import { useDataSubmit, useDataChange } from 'Simple/Data.js'

function useChange(props, patient_new_or_existing) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })
  let change = useDataChange({
    context: 'appointment_overlay',
    path: 'patient_new_or_existing',
    viewPath: props.viewPath,
  })

  return () => {
    submit({ type: 'reset' })
    change(patient_new_or_existing)
  }
}

export function useOnNew(props) {
  return useChange(props, 'new')
}

export function useOnExisting(props) {
  return useChange(props, 'existing')
}
