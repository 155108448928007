import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let id = useDataValue({
    viewPath: props.viewPath,
    context: 'slot',
    path: 'id',
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'slot_id',
  })

  return () => {
    change(id)
  }
}
