// this is an autogenerated file from App/Account/Content/Calendar/New/AppointmentOverlay/Content/TypePreview/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_AppointmentOverlay_Content_TypePreview_Content($appointment_id: uuid!) {
  vaxiom_appointments(where: { _id: { _eq: $appointment_id } }) {
    id: _id
    type {
      id: _id
      name
      color_id
    }
  }
}

`