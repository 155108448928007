import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    // groupBy().map does not work, probably because of chaining
    return map(groupBy(data, 'tx.id'), appointments => {
      let {
        tx: { tx_card },
      } = appointments[0]

      return {
        tx_card,
        appointments,
      }
    })
  }, [data])
}
