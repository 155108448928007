import { useDataChange } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'appointments',
    path: 'tx_uuid',
  })

  return async function onChange(value) {
    let first = value?.[0]
    // is actually uuid, see useDataTransform
    if (first) change(first.id)
  }
}
