import { useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  return function onClick() {
    submit({
      type: 'tabs/add',
      tab: {
        type: 'Notes',
        id: 'Notes',
        name: 'Notes',
        viewPath: '/App/Account/Content/Notes',
        selected: {},
      },
    })
  }
}
