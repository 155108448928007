// this is an autogenerated file from App/Auth/Check/query-session.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Auth_Check($user_id: uuid!) {
  feature_flags: vaxiom_user_is_admin {
    vaxiom_user_id
    is_support
    is_apps_enabled
    is_new_profile_enabled
    is_new_profile_default_enabled
    is_new_tx_card_default_enabled
    is_new_calendar_enabled
    is_new_calendar_default_enabled
    is_new_reporting_default_enabled
    is_new_payments_enabled
    is_marketing_analytics_enabled
    is_pay_app_enabled
    is_new_tx_planning_enabled
    is_new_tx_planning_default_enabled
  }
  comms_user_preferences: comms_user_preferences_by_pk(user_id: $user_id) {
    user_id
    location_ids: data(path: "location_ids")
    mobile_numbers_outbound_ids: data(path: "mobile_numbers_outbound_ids")
  }
  user_preferences: user_preferences_by_pk(user_id: $user_id) {
    id: user_id
    user_id
    chair_ids
    current_location {
      id
      _id
      name
      time_zone_id
      local_current_date
      xid
      # TODO: replace for parent_company once it has been
      # refactored and is using the local ref to the PC
      parent {
        id
        parent {
          id
          _id
          name
          xid
        }
      }
    }
  }
  user_details: users_by_pk(id: $user_id) {
    id
    created_at
    account {
      id
      email
    }
    vaxiom_user {
      id
      role
      person {
        id
        title
        first_name
        last_name
        job_title
      }
    }
  }

  # TODO: move to a computed field on the location
  current_location_permissions {
    has_admin
    has_work_hours
    has_chairs
    has_employee_work_hours
    has_hr_admin
    has_notes
    has_office_calendar
    has_patients
    has_tasks
    has_financial
    has_reports
    has_patient_ledger
    has_patient_medical
    has_patient_profile
    has_patient_treatment
    has_patient_documents
    has_patient_images
    has_patient_insurance_edit
  }
  user_locations: session_user_locations(order_by: { name: asc }) {
    id
    _id
  }
}

`