import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return {
      ...data,
      steps: ['selection', 'schedule', 'setup', 'sign_contract'],
      current_step: 'selection',
    }
  }, [data])
}
