import React, { useEffect, useMemo, useCallback } from 'react'
import { useFloating, offset, flip, shift } from '@floating-ui/react-dom'

export default function TysiaCollapsibleFloatingPortal(props) {
  let { isVisible, setIsVisible } = props
  let {
    x,
    y,
    strategy,
    refs: { floating: sidebarRef, setFloating },
    update,
  } = useFloating({
    open: props.isFloating && isVisible,
    placement: props.placement,
    middleware: [offset(0), flip(), shift()],
  })

  let handleMouseMove = useCallback(
    event => {
      // Determine if the mouse is near the appropriate edge based on the placement
      let { clientX: mouseX, clientY: mouseY } = event

      switch (props.placement) {
        case 'top':
        case 'top-start':
        case 'top-end':
          if (mouseY < props.appearThreshold && !isVisible) {
            setIsVisible(true)
            update()
          } else if (mouseY > props.hideThreshold && isVisible) {
            setIsVisible(false)
          }
          break
        case 'right':
        case 'right-start':
        case 'right-end':
          if (
            window.innerWidth - mouseX < props.appearThreshold &&
            !isVisible
          ) {
            setIsVisible(true)
            update()
          } else if (
            window.innerWidth - mouseX > props.hideThreshold &&
            isVisible
          ) {
            setIsVisible(false)
          }
          break
        case 'bottom':
        case 'bottom-start':
        case 'bottom-end':
          if (
            window.innerHeight - mouseY < props.appearThreshold &&
            !isVisible
          ) {
            setIsVisible(true)
            update()
          } else if (
            window.innerHeight - mouseY > props.hideThreshold &&
            isVisible
          ) {
            setIsVisible(false)
          }
          break
        case 'left':
        case 'left-start':
        case 'left-end':
        default:
          if (mouseX < props.appearThreshold && !isVisible) {
            setIsVisible(true)
            update()
          } else if (mouseX > props.hideThreshold && isVisible) {
            setIsVisible(false)
          }
          break
      }
    },
    [
      props.appearThreshold,
      props.hideThreshold,
      props.placement,
      isVisible,
      setIsVisible,
      update,
    ]
  )

  let handleMouseLeave = useCallback(
    event => {
      // Collapse the sidebar when the mouse leaves the sidebar area
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.relatedTarget) &&
        isVisible
      ) {
        setIsVisible(false)
      }
    },
    [isVisible, setIsVisible]
  )

  useEffect(() => {
    // Add mousemove event listener to the document
    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [handleMouseMove])

  useEffect(() => {
    if (sidebarRef.current) {
      sidebarRef.current.addEventListener('mouseleave', handleMouseLeave)
    }

    return () => {
      if (sidebarRef.current) {
        sidebarRef.current.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [handleMouseLeave])

  let getTransform = useMemo(() => {
    switch (props.placement) {
      case 'top':
      case 'top-start':
      case 'top-end':
        return isVisible ? 'translateY(0)' : 'translateY(-100%)'
      case 'right':
      case 'right-start':
      case 'right-end':
        return isVisible ? 'translateX(0)' : 'translateX(100%)'
      case 'bottom':
      case 'bottom-start':
      case 'bottom-end':
        return isVisible ? 'translateY(0)' : 'translateY(100%)'
      case 'left':
      case 'left-start':
      case 'left-end':
      default:
        return isVisible ? 'translateX(0)' : 'translateX(-100%)'
    }
  }, [props.placement, isVisible])

  let size = useMemo(() => {
    switch (props.placement) {
      case 'top':
      case 'top-start':
      case 'top-end':
      case 'bottom':
      case 'bottom-start':
      case 'bottom-end':
        return {
          width: props.width ?? '100%',
          height: props.height,
          isHorizontal: true,
        }
      case 'right':
      case 'right-start':
      case 'right-end':
      case 'left':
      case 'left-start':
      case 'left-end':
      default:
        return {
          width: props.width,
          height: props.height ?? '100%',
          isHorizontal: false,
        }
    }
  }, [props.placement, props.width, props.height])

  let alignment = useMemo(() => {
    switch (props.placement) {
      case 'left':
      case 'left-start':
      case 'left-end':
      case 'top':
      case 'top-start':
      case 'top-end':
        return { top: y ?? 0, left: x ?? 0 }

      case 'right':
      case 'right-start':
      case 'right-end':
      case 'bottom':
      case 'bottom-end':
      case 'bottom-start':
      default:
        return { bottom: y ?? 0, right: x ?? 0 }
    }
  }, [props.placement, x, y])

  return props.isFloating ? (
    <div
      ref={setFloating}
      style={{
        position: strategy,
        width: size.width,
        height: size.height,
        backgroundColor: 'white',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        pointerEvents: isVisible ? 'auto' : 'none',
        transition: 'transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1.0)',
        transform: getTransform,
        zIndex: 100,
        ...alignment,
        ...props,
      }}
    >
      {typeof props.children === 'function' ? props.children() : props.children}
    </div>
  ) : typeof props.children === 'function' ? (
    props.children()
  ) : (
    props.children
  )
}
