// this is an autogenerated file from App/Account/Content/Calendar/New/AppointmentOverlay/Content/Form/Content/TemplateSelect/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_AppointmentOverlay_Content_Form_Content_TemplateSelect_Content($location_id: uuid!) {
  vaxiom_appointment_templates(
    where: {
      deleted: { _eq: false }
      organization: { _id: { _eq: $location_id } }
    }
    order_by: { full_name_computed: asc }
  ) {
    id: _id
    text: full_name_computed
    color_id_computed
  }
}

`