import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'global',
  })
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: '_id',
  })

  return async () => {
    await submit({
      type: 'tabs/add',
      tab: {
        id: 'Calendar',
        name: 'Calendar',
        type: 'Calendar',
        viewPath: '/App/Account/Content/Calendar',
        appointmentOverlayParams: {
          patient_id,
        },
      },
    })
    submit({ type: 'patientOverlay/close' })
  }
}
