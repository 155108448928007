// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsEdit/Content/Email/Emails/Content/Email/Related/Content/Popup/Content/Persons/Person/Error/ErrorAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomOtherProfessionalsEdit_Content_Email_Emails_Content_Email_Related_Content_Popup_Content_Persons_Person_Error_ErrorAction(
  $association_id: numeric!
  $error_date: date!
  $location_id: numeric!
) {
  update_vaxiom_contact_method_associations_by_pk(
    pk_columns: { id: $association_id }
    _set: { error_date: $error_date, error_location_id: $location_id }
  ) {
    id
  }
}

`