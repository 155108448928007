import React, { useRef } from 'react'
import '@pqina/pintura/pintura.css'
import './style.css'
import { PinturaEditor } from '@pqina/react-pintura'
import {
  // editor
  locale_en_gb,
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  createMarkupEditorToolStyles,
  createMarkupEditorToolStyle,
  createDefaultColorOptions,
  colorStringToColorArray,

  // plugins
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_finetune,
  plugin_finetune_locale_en_gb,
  plugin_finetune_defaults,
  plugin_filter,
  plugin_filter_locale_en_gb,
  plugin_filter_defaults,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
} from '@pqina/pintura'

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate)

let editorDefaults = {
  utils: ['crop', 'finetune', 'filter', 'annotate'],
  util: 'crop',
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter(),
  shapePreprocessor: createDefaultShapePreprocessor(),
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...markup_editor_defaults,
  markupEditorToolStyles: createMarkupEditorToolStyles({
    rectangle: createMarkupEditorToolStyle('rectangle', {
      strokeWidth: '1%',
      strokeColor: createDefaultColorOptions().red,
      backgroundColor: createDefaultColorOptions().transparent,
    }),
    ellipse: createMarkupEditorToolStyle('ellipse', {
      strokeWidth: '1%',
      strokeColor: createDefaultColorOptions().red,
      backgroundColor: createDefaultColorOptions().transparent,
    }),
  }),
  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    ...plugin_annotate_locale_en_gb,
    ...markup_editor_locale_en_gb,
  },
}

export default function ImageEditor(props) {
  let editorRef = useRef(null)
  let stateRef = useRef({
    ready: false,
    updatedCalled: false,
    firstImageState: null,
  })

  return (
    <PinturaEditor
      {...editorDefaults}
      // force recreating the image editor to reset the internal state
      key={props.src}
      src={props.src}
      imageBackgroundColor={colorStringToColorArray('#ffffff')}
      imageCropAspectRatio={props.aspectRatio}
      imageCropLimitToImage={false}
      enableButtonExport={
        typeof props.enableButtonExport === 'boolean'
          ? props.enableButtonExport
          : true
      }
      enableViewTool
      cropEnableInfoIndicator
      cropEnableButtonFlipVertical
      cropRotationRange={Math.PI}
      cropWillRenderImageSelectionGuides={() => ({
        rows: 0,
        cols: 0,
        opacity: 0,
      })}
      ref={editorRef}
      onReady={() => {
        props.onInit && props.onInit(editorRef.current)
      }}
      onLoad={() => {
        stateRef.current.ready = true
      }}
      onUpdate={imageState => {
        if (!stateRef.current.ready) return
        if (stateRef.current.updatedCalled) return
        if (stateRef.current.firstImageState === JSON.stringify(imageState))
          return
        if (stateRef.current.firstImageState === null) {
          stateRef.current.firstImageState = JSON.stringify(imageState)
          return
        }

        stateRef.current.updatedCalled = true

        props.onUpdate && props.onUpdate()
      }}
      onProcess={data => {
        props.onSave && props.onSave(data.dest)
      }}
      willRenderCanvas={props.willRenderCanvas}
    />
  )
}
