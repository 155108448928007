import { today } from 'Data/format.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataChange, useDataSubmit, useDataValue } from 'Simple/Data'
import { useClient, useMutation } from 'Data/Api'
import { login } from 'Data/Auth'
import mutationSetCurrentLocation from './mutation-set-current-location.graphql.js'
import mutationLoginXid from './mutation-login-xid.graphql.js'
import querySession from './query-session.graphql.js'
import queryChairs from './query-chairs.graphql.js'
import { useSetFlowTo } from 'Simple/Flow.js'

export default function useDataOnSubmit(props) {
  let [, notify] = useNotifications()

  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  let changeAuth = useDataChange({
    viewPath: props.viewPath,
    context: 'auth',
  })
  let global_current_location = useDataValue({
    context: 'global',
    path: 'current_location',
    viewPath: props.viewPath,
  })
  let user_id = useDataValue({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data.user_id',
  })
  let [, executeMutationSetCurrentLocation] = useMutation(
    mutationSetCurrentLocation
  )
  let [, executeMutationLoginXid] = useMutation(mutationLoginXid)
  let client = useClient()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ args }) {
    let { current_location } = args

    // if the current location and the chosen location are the same
    // don't execute the mutation or it will override the saved chairs
    if (global_current_location?.id === current_location.id) {
      setFlowTo('/App/Account/Content/RedirectToDefaultTab')
      return
    }

    let auth = null
    let context = null

    if (
      global_current_location == null ||
      global_current_location?.xid !== current_location.xid
    ) {
      localStorage.removeItem('aws_credentials')
      let mutationResponseLoginXid = await executeMutationLoginXid({
        xid: current_location.xid,
      })

      if (
        mutationResponseLoginXid.error ||
        mutationResponseLoginXid.data.vaxiom_users_login_xid.status !== 'ok'
      ) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )
        return
      }

      auth = login(mutationResponseLoginXid.data.vaxiom_users_login_xid)

      // If the token was refreshed during the Check process, we need to
      // pass the credentials over to urql, otherwise Api didn't have time
      // pick up the new credentials from Auth and it kicks the user out.
      // To make things simpler always run queries in this method enforcing
      // a specific context.
      // In fairness, what would make sense here is that the logic to pull
      // session data is elsewhere (like Account) but this was the easiest
      // when refactoring all that stuff at the time.
      context = {
        fetchOptions: {
          headers: {
            'x-hasura-role': auth.api_role,
            Authorization: `Bearer ${auth.access_token}`,
          },
        },
      }
    }

    let { data } = await client
      .query(queryChairs, { current_location_id: current_location.id }, context)
      .toPromise()
    let mutationResponse = await executeMutationSetCurrentLocation(
      {
        current_location_id: current_location.id,
        chair_ids: data.vaxiom_chairs.map(chair => chair._id),
      },
      context
    )
    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    // TODO: maybe we merge getting the permissions/session info into 'session/set' for both places?
    let { data: session } = await client
      .query(querySession, { user_id }, context)
      .toPromise()

    let {
      parent: { parent: parent_company },
      ...location
    } = session.user_preferences.current_location

    let user_details = {
      first_name: session.user_details.vaxiom_user.person.first_name,
      last_name: session.user_details.vaxiom_user.person.last_name,
      email: session.user_details.account.email,
      title: session.user_details.vaxiom_user.person.title || '',
      job_title: session.user_details.vaxiom_user.person.job_title || '',
      role: session.user_details.vaxiom_user.role || '',
    }

    localStorage.setItem('choseLocationAfterLogin', today())
    localStorage.setItem('preferredXid', current_location.xid)

    // do this at the end to delay creating a new urql client as much as possible
    // there's a better way to do this
    if (auth !== null) {
      changeAuth(auth)
    }

    submit({
      type: 'session/set',
      current_location: {
        ...location,
        parent_company,
        permissions: session.current_location_permissions,
      },
      feature_flags: session.feature_flags,
      user: {
        id: user_id,
        ...user_details,
        preferences: {
          chair_ids: session.user_preferences.chair_ids,
        },
        comms_preferences: session.comms_user_preferences || {
          location_ids: null,
          mobile_numbers_outbound_ids: null,
        },
      },
    })

    setFlowTo('/App/Account/Content/RedirectToDefaultTab')
  }
}
