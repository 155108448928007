// this is an autogenerated file from App/Account/Content/Calendar/New/Topbar/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Topbar($location_id: uuid!) {
  calendar_settings(where: { location_id: { _eq: $location_id } }) {
    id
    user_id
    location_id
    hipaa
    slot_interval
    selected_appointment_templates
  }
}

`