// this is an autogenerated file from App/Account/Content/Calendar/New/AppointmentOverlay/Content/Form/Content/AppointmentSelect/Content/Selected/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_AppointmentOverlay_Content_Form_Content_AppointmentSelect_Content_Selected_Content($appointment_id: uuid!) {
  vaxiom_appointments(where: { _id: { _eq: $appointment_id } }) {
    id: _id
    type {
      id: _id
      full_name
      color_id
    }
    tx {
      id: _id
      tx_card {
        id: _id
        name
      }
    }
    booking {
      id: _id
      local_start_date
      local_start_time
      duration
      state
    }
  }
}

`