import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'
import {
  notifyInvalid,
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataSubmit, useDataValue, useDataChange } from 'Simple/Data.js'

import mutationAdd from './mutation_add.graphql.js'
import mutationUpdate from './mutation_update.graphql.js'
import mutationRemove from './mutation_remove.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let paymentSubmit = useDataSubmit({
    context: 'payment',
    viewPath: props.viewPath,
  })
  let [, executeMutationAdd] = useMutation(mutationAdd)
  let [, executeMutationUpdate] = useMutation(mutationUpdate)
  let [, executeMutationRemove] = useMutation(mutationRemove)
  let payment_plan_id = useDataValue({
    context: 'edit_autopay_info',
    path: 'payment_plan.id',
    viewPath: props.viewPath,
  })
  let original_autopay_source = useDataValue({
    context: 'edit_autopay_info',
    path: 'payment_plan.autopay_source',
    viewPath: props.viewPath,
  })
  let refreshEmbeddedSection = useDataChange({
    context: 'global',
    path: 'refresh_embedded_section',
    viewPath: props.viewPath,
  })

  return async function onAction({ value }) {
    let validation = validate(value)

    if (validation.isInvalid) {
      return notify(notifyError(validation.errors[0]))
    }

    switch (value.method) {
      case 'autopay': {
        try {
          let token = await paymentSubmit({ type: 'tokenize' })

          // true means invalid in this context
          if (token === true || !token) return true
          let isUpdate = original_autopay_source !== null

          let executeMutation = isUpdate
            ? executeMutationUpdate
            : executeMutationAdd

          let mutationResponse = await executeMutation({
            payment_plan_id,
            token,
          })

          if (mutationResponse.error) {
            notify(
              notifyError(
                'There was an issue taking the payment. Please try again.'
              )
            )
            break
          }

          let autopay = isUpdate
            ? mutationResponse.data.payments_update_payment_plan_autopay
            : mutationResponse.data.payments_add_payment_plan_autopay

          if (autopay.verified) {
            notify(notifySuccess('Update was successful'))
          } else {
            notify(
              notifyInvalid([
                'Autopayment updated. verification failed with: ',
                autopay.verification_metadata.auth_message,
              ])
            )
          }
          setFlowTo(normalizePath(props.viewPath, '../../No'))
          refreshEmbeddedSection(Date.now())
          break
        } catch (error) {
          notify(
            notifyError(
              error?.message ??
                'Something went wrong while setting method to Autopay'
            )
          )
          break
        }
      }
      case 'invoice': {
        try {
          let mutationResponse = await executeMutationRemove({
            payment_plan_id,
          })

          if (mutationResponse.error) throw mutationResponse.error

          notify(notifySuccess('Update was successful'))
          setFlowTo(normalizePath(props.viewPath, '../../No'))
          refreshEmbeddedSection(Date.now())
          break
        } catch (error) {
          notify(
            notifyError(
              error?.message ??
                'Something went wrong while setting method to Invoice'
            )
          )
          break
        }
      }
      default: {
        notify(notifyError('Something went wrong, unknown method'))
      }
    }
  }
}

function validate(value) {
  let errors = []

  let { autopay_source_id, method } = value

  if (method === 'autopay' && !autopay_source_id) {
    errors.push('Please select autopay source')
  }

  return {
    isInvalid: Boolean(errors.length),
    errors,
  }
}
