// this is an autogenerated file from App/Account/Content/Calendar/New/CollapsibleSidebar/Filters/ProvidersAndAssistants/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_CollapsibleSidebar_Filters_ProvidersAndAssistants($location_id: uuid!) {
  vaxiom_resources(
    where: {
      organization: { _id: { _eq: $location_id } }
      resource_type: {
        _or: [{ is_provider: { _eq: true } }, { is_assistant: { _eq: true } }]
      }
      employee_resource: {
        invalid: { _eq: false }
        employment_contract: { employment_end_date: { _is_null: true } }
      }
    }
  ) {
    id
    employee_resource {
      id
      employment_contract {
        id
        person {
          id
          first_name
          middle_name
          last_name
        }
      }
    }
    resource_type {
      id
      is_assistant
      is_provider
    }
  }
}

`