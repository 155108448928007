import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { dateShortOut } from 'Data/format.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let person = useDataValue({
    viewPath: props.viewPath,
    context: 'person',
  })
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.id',
  })
  let [, executeMutation] = useMutation(mutation)
  return async function onClick(value) {
    let mutationResponse = await executeMutation({
      association_id: person.id,
      location_id,
      error_date: dateShortOut(new Date()),
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    setFlowTo(normalizePath(props.viewPath, '../../../../../../No'))
    return
  }
}
