import { useDataValue, useDataChange } from 'Simple/Data'
import { setMonth } from 'date-fns'

export function useOnClick(props) {
  let current_date = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected.date',
  })

  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  let index = useDataValue({
    context: 'month_item',
    viewPath: props.viewPath,
    path: 'index',
  })

  return function onClick() {
    if (index !== null) {
      change(next => {
        next.selected.date = setMonth(current_date, index)
      })

      if (typeof props.toggle === 'function') props.toggle()
    }
  }
}
