// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connection/Content/SetupAutomations/Content/Content/ActiveStep/Automation/TxStatus/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connection_Content_SetupAutomations_Content_Content_ActiveStep_Automation_TxStatus{
  vaxiom_tx_statuses(order_by: { name: asc_nulls_last }) {
    id: type
    text: name
  }
}

`