import {
  addMonths as _addMonths,
  subMonths as _subMonths,
  addDays as _addDays,
  addWeeks as _addWeeks,
  subDays as _subDays,
  startOfMonth as _startOfMonth,
  endOfMonth as _endOfMonth,
  setDate as _setDate,
  setMonth as _setMonth,
  getMonth as _getMonth,
  getDaysInMonth as _getDaysInMonth,
  isBefore as _isBefore,
  isAfter as _isAfter,
  isEqual as _isEqual,
  differenceInCalendarMonths as _differenceInCalendarMonths,
  differenceInDays as _differenceInDays,
  addYears as _addYears,
  subYears as _subYears,
} from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

/**
 *
 * @param {Date} date
 */
export function flattenToUTC(date) {
  date.setUTCHours(0)
  date.setUTCMinutes(0)
  date.setUTCSeconds(0)
  date.setUTCMilliseconds(0)

  return date
}

/**
 * Wraps a date-fns function to ensure it operates in UTC.
 * @param {(date: Date, amount: number) => Date} fn - The date-fns function to wrap.
 * @returns {(date: Date, amount: number) => Date} The wrapped function that operates in UTC.
 */
let withUTC = fn => (date, amount) =>
  flattenToUTC(zonedTimeToUtc(fn(utcToZonedTime(date, 'UTC'), amount), 'UTC'))

/**
 * Wraps a date-fns function to ensure it operates in UTC.
 * @param {(date: Date) => Date} fn - The date-fns function to wrap.
 * @returns {(date: Date) => Date} The wrapped function that operates in UTC.
 */
let withUTCNoAmount = fn => date =>
  flattenToUTC(zonedTimeToUtc(fn(utcToZonedTime(date, 'UTC')), 'UTC'))

/**
 * Wraps a date-fns function to ensure it operates in UTC.
 * @param {(date: Date) => number} fn - The date-fns function to wrap.
 * @returns {(date: Date) => number} The wrapped function that operates in UTC.
 */
let withUTCReturnsNumber = fn => date => fn(utcToZonedTime(date, 'UTC'))

/**
 * Wraps a date-fns function to ensure it operates in UTC.
 * @param {(date1: Date, date2: Date) => number} fn - The date-fns function to wrap.
 * @returns {(date1: Date, date2: Date) => number} The wrapped function that operates in UTC.
 */
let withUTCCompareNumber = fn => (date1, date2) =>
  fn(utcToZonedTime(date1, 'UTC'), utcToZonedTime(date2, 'UTC'))

/**
 * Wraps a date-fns function to ensure it operates in UTC.
 * @param {(date1: Date, date2: Date) => boolean} fn - The date-fns function to wrap.
 * @returns {(date1: Date, date2: Date) => boolean} The wrapped function that operates in UTC.
 */
let withUTCCompareBoolean = fn => (date1, date2) =>
  fn(utcToZonedTime(date1, 'UTC'), utcToZonedTime(date2, 'UTC'))

export let addMonths = withUTC(_addMonths)
export let subMonths = withUTC(_subMonths)
export let addYears = withUTC(_addYears)
export let subYears = withUTC(_subYears)
export let addDays = withUTC(_addDays)
export let addWeeks = withUTC(_addWeeks)
export let subDays = withUTC(_subDays)
export let setDate = withUTC(_setDate)
export let setMonth = withUTC(_setMonth)

export let startOfMonth = withUTCNoAmount(_startOfMonth)
export let endOfMonth = withUTCNoAmount(_endOfMonth)

export let getDaysInMonth = withUTCReturnsNumber(_getDaysInMonth)
export let getMonth = withUTCReturnsNumber(_getMonth)

export let isBefore = withUTCCompareBoolean(_isBefore)
export let isAfter = withUTCCompareBoolean(_isAfter)
export let isEqual = withUTCCompareBoolean(_isEqual)

export let differenceInCalendarMonths = withUTCCompareNumber(
  _differenceInCalendarMonths
)
export let differenceInDays = withUTCCompareNumber(_differenceInDays)

export { isWithinInterval } from 'date-fns'

export { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
