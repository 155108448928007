import React, { useLayoutEffect, useRef } from 'react'
import View from './view.js'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  let locationId = useDataValue({
    context: 'payment',
    path: 'location_id',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'payment',
    viewPath: props.viewPath,
  })
  let submitRef = useRef(submit)

  useLayoutEffect(() => {
    submitRef.current = submit
  }, [submit])

  useLayoutEffect(() => {
    if (!locationId || !props.person?._id || !props.method) return

    submitRef.current({
      type: 'initialize',
      method: props.method,
      locationId,
      person: props.person,
    })
  }, [props.method, locationId, props.person])

  return <View {...props} />
}
