// this is an autogenerated file from App/Account/Content/Calendar/New/Topbar/Content/FilterAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Calendar_New_Topbar_Content_FilterAction(
  $location_id: numeric!
  $objects: [vaxiom_selected_appointment_templates_insert_input!]!
) {
  delete_vaxiom_selected_appointment_templates(
    where: { location_id: { _eq: $location_id } }
  ) {
    affected_rows
    returning {
      id
      location_id
      appointment_template_id
    }
  }

  insert_vaxiom_selected_appointment_templates(objects: $objects) {
    affected_rows
    returning {
      id
      location_id
      appointment_template_id
    }
  }
}

`