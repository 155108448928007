import View from './view.js'
import { useMemo, useRef } from 'react'
import { useDataValue } from 'Simple/Data'

export default function Logic(props) {
  let innerRef = useRef()

  let isFloatingAndVisible = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'sidebar.isFloatingAndVisible',
  })

  let sidebarTopSpace = useMemo(() => {
    if (innerRef.current) {
      return innerRef.current.offsetHeight
    } else return 0
  }, [isFloatingAndVisible])

  let sidebarHeight = useMemo(() => {
    return `calc(100vh - ${sidebarTopSpace}px)`
  }, [sidebarTopSpace])

  return (
    <View
      innerRef={innerRef}
      sidebarTopSpace={sidebarTopSpace}
      sidebarHeight={sidebarHeight}
      {...props}
    />
  )
}
