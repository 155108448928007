// this is an autogenerated file from App/Account/Content/PatientsList/Filters/StatusChooser/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_PatientsList_Filters_StatusChooser{
  vaxiom_txs(distinct_on: [status], order_by: [{ status: asc }]) {
    id
    text: status
  }
}

`