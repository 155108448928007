import { useDataChange } from 'Simple/Data.js'

export function useOnClearAll(props) {
  let change = useDataChange({
    context: 'list_dialog',
    viewPath: props.viewPath,
  })

  return function onClearAll() {
    change(next => {
      next.selected = []
    })
    if (typeof props.onClearAll === 'function') props.onClearAll()
  }
}
