import React, { useRef } from 'react'
import View from './view.js'
import useOnClickOutside from 'use-onclickoutside'
import { normalizePath, useSetFlowTo, useFlowValue } from 'Simple/Flow.js'

export default function Logic(props) {
  let buttonRef = useRef()
  let contentRef = useRef()
  let flow = useFlowValue(props.viewPath)
  let setFlowTo = useSetFlowTo(props.viewPath)

  useOnClickOutside(contentRef, event => {
    if (
      event.target === buttonRef.current ||
      buttonRef.current?.contains(event.target) ||
      flow === 'No'
    ) {
      return
    }

    setFlowTo(normalizePath(props.viewPath, 'No'))
  })

  return (
    <View
      {...props}
      buttonRef={buttonRef}
      contentRef={contentRef}
      onClick={onClick}
      selected={flow === 'Content'}
    />
  )

  function onClick(event) {
    if (flow === 'Content') {
      setFlowTo(normalizePath(props.viewPath, 'No'))
    } else {
      setFlowTo(normalizePath(props.viewPath, 'Content'))
    }
  }
}
