let STATES_BY_TAB = {
  all: ['SCHEDULED', 'OFFICE_CANCELLED', 'PATIENT_CANCELLED', 'NO_SHOW'],
  scheduled: ['SCHEDULED'],
  cancelled: ['OFFICE_CANCELLED', 'PATIENT_CANCELLED'],
  no_show: ['NO_SHOW'],
}

export function filterByActiveTab(tab, appointment_bookings) {
  let states = STATES_BY_TAB[tab]
  return appointment_bookings.filter(booking => states.includes(booking.state))
}
