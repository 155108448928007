import { age, dateShortIn } from 'Data/format.js'

export function format(person, hipaa) {
  /** @type {(string | number)[]} */
  let result = [
    [person.first_name, !hipaa && person.last_name].filter(Boolean).join(' '),
  ]

  if (person.gender) result.push(person.gender[0])

  if (person.birth_date) {
    result.push(age(person.birth_date), dateShortIn(person.birth_date))
  }

  return result.filter(Boolean).join(', ')
}
