// this is an autogenerated file from App/Account/Content/Calendar/New/CollapsibleSidebar/MonthlyCalendar/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_CollapsibleSidebar_MonthlyCalendar(
  $location_id: uuid!
  $start_date: date!
  $end_date: date!
) {
  chairs: vaxiom_chairs(
    where: { resource: { organization: { _id: { _eq: $location_id } } } }
  ) {
    id
    appointment_bookings(
      where: {
        local_start_date: { _gte: $start_date, _lte: $end_date }
        state: { _nin: ["PATIENT_CANCELLED", "OFFICE_CANCELLED"] }
      }
    ) {
      id
      local_start_date
      duration
    }
    resource {
      id
      work_schedule_days(
        where: { ws_date: { _gte: $start_date, _lte: $end_date } }
      ) {
        id
        ws_date
        day_schedule {
          id
          day_schedule_office_hours {
            start_min
            end_min
          }
        }
      }
    }
  }
  offices: vaxiom_offices(
    where: { resource: { organization: { _id: { _eq: $location_id } } } }
  ) {
    id
    resource {
      id
      day_schedules {
        id
        day_schedule_office_hours {
          start_min
          end_min
        }
      }
    }
  }
  office_days: vaxiom_office_days(
    where: {
      location: { _id: { _eq: $location_id } }
      o_date: { _gte: $start_date, _lte: $end_date }
    }
    distinct_on: o_date
    order_by: { o_date: asc }
  ) {
    id
    o_date
    template {
      id
      name
      color
    }
  }

  allocations: vaxiom_chair_allocations(
    where: {
      ca_date: { _gte: $start_date, _lte: $end_date }
      resource: {
        organization: { _id: { _eq: $location_id } }
        resource_type: {
          _or: [{ is_assistant: { _eq: true } }, { is_provider: { _eq: true } }]
        }
      }
    }
    order_by: { ca_date: asc }
  ) {
    id
    ca_date
    resource {
      id
      employee_resources {
        id
        employment_contract {
          id
          person {
            id
            greeting
            first_name
            last_name
          }
        }
      }
      resource_type {
        id
        is_assistant
        is_provider
      }
    }
  }
}

`