import { useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { downloadCsv } from 'Logic/csv.js'

export function useOnClick(props) {
  let results = useDataValue({
    context: 'marketing_analytics',
    path: 'leads',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    try {
      let data = results.map(item =>
        Object.values(item).map(value =>
          typeof value === 'object' && value !== null
            ? JSON.stringify(value)
            : value
        )
      )

      let fields = results.reduce((keys, item) => {
        Object.keys(item).forEach(key => {
          keys.add(key)
        })
        return keys
      }, new Set())

      downloadCsv({
        name: 'marketing-analytics-report.csv',
        fields: Array.from(fields),
        data,
      })

      setFlowTo(normalizePath(props.viewPath, '../Done'))
    } catch (e) {
      console.error(e)
      setFlowTo(normalizePath(props.viewPath, '../Failure'))
    }
  }
}
