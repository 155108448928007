import './Calendar.css'
import CalendarHeader from '../TysiaCalendarHeader/view.js'
import CalendarNav from '../TysiaCalendarNav/logic.js'
import Day from '../TysiaCalendarDay/logic.js'
import DayPicker from 'react-day-picker'
import parse from 'date-fns/parse'
import groupBy from 'lodash/groupBy'
import format from 'date-fns/format'
import React, { useMemo } from 'react'

let SUNDAY = 0
let SATURDAY = 6
let MODIFIERS = {
  sundays: { daysOfWeek: [0] },
  saturdays: { daysOfWeek: [6] },
}

export default function Calendar({
  onChange,
  value,
  monthSlots: monthSlotsRaw,
  useRaw,
  month,
  onClickPrevMonth,
  onClickNextMonth,
  timezone,
  viewPath,
  showWeekendDays = false,
  showNavBar = true,
  renderDay,
  weekdayElement,
}) {
  let [monthSlots, visibleDays] = useMemo(() => {
    let monthSlots = groupBy(monthSlotsRaw, item => item.start?.split('T')[0])

    return useRaw
      ? [monthSlotsRaw, getVisibleDays(monthSlotsRaw)]
      : [monthSlots, getVisibleDays(monthSlots)]
  }, [monthSlotsRaw, useRaw])

  return (
    <>
      {showNavBar && (
        <CalendarNav
          onClickPrevMonth={onClickPrevMonth}
          onClickNextMonth={onClickNextMonth}
          month={month}
          viewPath={`${viewPath}/TysiaCalendarNav`}
        />
      )}
      <DayPicker
        viewPath={`${viewPath}/TysiaCalendarDayPicker`}
        captionElement={() => null}
        month={month}
        disabledDays={[
          {
            before: new Date(),
          },
        ]}
        modifiers={MODIFIERS}
        modifiersStyles={{
          sundays: {
            display: visibleDays[SUNDAY] ? 'flex' : 'none',
          },
          saturdays: {
            display: visibleDays[SATURDAY] ? 'flex' : 'none',
          },
        }}
        navbarElement={() => null}
        renderDay={typeof renderDay === 'function' ? renderDay : _renderDay}
        weekdayElement={
          typeof weekdayElement === 'function'
            ? weekdayElement
            : _weekdayElement
        }
      />
    </>
  )

  function _renderDay(day, modifiers) {
    let date = format(day, 'yyyy-MM-dd')
    let slots = monthSlots[date] || []

    return (
      <Day
        viewPath={`${viewPath}/TysiaCalendarDayPicker/TysiaCalendarDay`}
        day={day}
        isDisabled={modifiers.disabled || slots.length === 0}
        onChange={onChange}
        slots={slots}
        value={value}
        timezone={timezone}
      />
    )
  }

  function _weekdayElement(props) {
    return visibleDays[props.weekday] ? (
      <CalendarHeader
        viewPath={`${viewPath}/TysiaCalendarDayPicker/TysiaCalendarHeader`}
        date={props.localeUtils.formatWeekdayShort(props.weekday)}
      />
    ) : null
  }

  function getVisibleDays(monthSlots) {
    // TODO: Do we want to keep this logic about, only show saturday and sunday when we have available slots?
    let weekDays = {
      0: showWeekendDays,
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: showWeekendDays,
    }

    Object.keys(monthSlots).forEach(day => {
      let dayParsed = parse(day, 'yyyy-MM-dd', new Date()).getDay()

      if (dayParsed === SUNDAY && monthSlots[day].length > 0) {
        weekDays[SUNDAY] = true
      } else if (dayParsed === SATURDAY && monthSlots[day].length > 0) {
        weekDays[SATURDAY] = true
      }
    })

    return weekDays
  }
}
