// this is an autogenerated file from App/Account/Topbar/LoggedInUser/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Topbar_LoggedInUser($id: uuid!) {
  users_by_pk(id: $id) {
    id
    person: vaxiom_person {
      id
      first_name
      last_name
    }
  }
}

`