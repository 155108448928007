// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/SyncPatients/Content/SyncPatients/Content/TreatmentStatus/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_SyncPatients_Content_SyncPatients_Content_TreatmentStatus{
  vaxiom_tx_statuses(order_by: { name: asc_nulls_last }) {
    id
    type
    name
  }
}

`