import { useDataChange } from 'Simple/Data.js'

export function useOnChangeFilter(props) {
  let change = useDataChange({
    context: 'existing_patient_select',
    path: 'search_term',
    viewPath: props.viewPath,
  })

  return v => {
    change(v)
  }
}
