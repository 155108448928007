import { DEFAULT_TIMEZONE } from 'Data/constants.js'
import { format, utcToZonedTime } from 'Data/date.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    let time_zone_id = props.timeZoneId || DEFAULT_TIMEZONE
    return {
      ...data,
      events: props.events || data.events,
      resources: props.resources || data.resources,
      date: props.date || new Date(),
      time_zone_id,
      slot_interval: props.slotInterval || data.slot_interval,
      scroll_time: format(utcToZonedTime(new Date(), time_zone_id), 'HH:mm:ss'),
      slot_min_time: props.slotMinTime || data.slot_min_time,
      slot_max_time: props.slotMaxTime || data.slot_max_time,
    }
  }, [
    data,
    props.events,
    props.resources,
    props.date,
    props.timeZoneId,
    props.slotInterval,
    props.slotMinTime,
    props.slotMaxTime,
  ])
}
