import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props) {
  let { receivable_id } = useDataValue({
    context: 'message',
    path: 'params',
    viewPath: props.viewPath,
  })

  return {
    variables: { receivable_id },
    pause: !receivable_id,
  }
}
