import { useDataChange } from 'Simple/Data.js'

export function useOnMouseLeave(props) {
  let change = useDataChange({
    context: 'popover',
    viewPath: props.viewPath,
  })
  return function onMouseLeave() {
    change(next => {
      next.isHoverOpen = false
    })
  }
}
