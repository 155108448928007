// this is an autogenerated file from DesignSystem/ManualAutomations/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_ManualAutomations($action_executions: jsonb!) {
  events_manual_event_execution_submit(action_executions: $action_executions) {
    status
    action_executions
  }
}

`