import { useDataChange, useDataSubmit } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return () => {
    submit({ type: 'reset', preservePatientId: true })
    change(next => {
      next.appointment_id = null
    })
    // close popup
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
