import React, { useRef } from 'react'
import View from './view.js'
import useIsHovered from 'Logic/useIsHovered.js'
import { useFloating, flip, offset, shift } from '@floating-ui/react-dom'

export default function Logic(props) {
  let timerRef = useRef(null)
  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: false,
    isSelected: false,
  })

  let { refs, x, y } = useFloating({
    open: hovered,
    placement: 'right-start',
    strategy: 'fixed',
    middleware: [
      offset(-12),
      flip({
        fallbackPlacements: ['right-end', 'left-start', 'left-end'],
      }),
      shift(),
    ],
  })

  return (
    <View
      {...props}
      hovered={hovered}
      isOpen={hovered}
      onMouseEnterTrigger={() => {
        timerRef.current = setTimeout(() => {
          hoverBind.onMouseEnter()
        }, 1000)
      }}
      onMouseEnterContent={() => {
        clearTimeout(timerRef.current)
      }}
      onMouseLeave={() => {
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
          hoverBind.onMouseLeave()
        }, 200)
      }}
      triggerRef={refs.setReference}
      contentRef={refs.setFloating}
      left={x}
      top={y}
    />
  )
}
