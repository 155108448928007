import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return () => {
    submit({ type: 'appointmentOverlay/open' })
  }
}
