// this is an autogenerated file from DesignSystem/EditAutomation/EventActions/Content/EventAction/Content/StatusUpdate/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_EditAutomation_EventActions_Content_EventAction_Content_StatusUpdate{
  vaxiom_tx_statuses(order_by: { name: asc }) {
    id
    type
    text: name
  }
}

`