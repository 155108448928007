import { useDataSubmit } from 'Simple/Data.js'

export function useOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'appointment_types',
    viewPath: props.viewPath,
  })

  return function onSubmit(selected_appointment_types) {
    submit(selected_appointment_types)
    if (typeof props.toggle === 'function') props.toggle()
  }
}

export function useOnFilter(props) {
  return function onFilter(value, items) {
    let valueRegex = new RegExp(value, 'i')
    return items.filter(item => valueRegex.test(item.full_name))
  }
}

export function useOnClearAll(props) {
  let submit = useDataSubmit({
    context: 'appointment_types',
    viewPath: props.viewPath,
  })
  return function onClearAll() {
    submit([])
    if (typeof props.toggle === 'function') props.toggle()
  }
}
