// this is an autogenerated file from DesignSystem/TimelineEvent/Note/Content/Edit/Content/mutation_delete.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Note_Content_Edit_Content($note_id: numeric!) {
  delete_vaxiom_notes_by_pk(id: $note_id) {
    id
    note
  }
}

`