import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    let items = props.items || data.items
    let selected = props.selected || data.selected

    return {
      ...data,
      items,
      filtered_items: items,
      selected,
      has_onsubmit: typeof props.onSubmit === 'function',
      // TODO: Come back to this to evaluate if we can replace this with a validation function
      disableSubmitIfEmptySelection:
        props.disableSubmitIfEmptySelection ?? true,
      onFilter: props.onFilter,
      showClearAll: props.showClearAll ?? false,
    }
  }, [data, props.items, props.selected, props.disableSubmitIfEmptySelection])
}
