// this is an autogenerated file from DesignSystem/PaymentProvider/PaymentProviderMethod/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PaymentProvider_PaymentProviderMethod(
  $person_id: uuid!
  $type: payments_token_types_enum!
  $token_ids: [uuid!]!
) {
  payments_tokens(
    where: {
      status: { _eq: active }
      provider_location_account: { is_default: { _eq: true } }
      type: { _eq: $type }
      _or: [
        { id: { _in: $token_ids } }
        { person_id: { _eq: $person_id }, reusable: { _eq: true } }
      ]
    }
  ) {
    id
    type
    last_4: metadata(path: "last_4")
    exp: metadata(path: "exp")
    card_brand: metadata(path: "card_brand")
    holder_name: metadata(path: "holder_name")
    provider_location_account {
      id
      location {
        id
        name
      }
    }
  }
}

`