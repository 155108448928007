import { subMonths, addMonths } from 'date-fns'
import { useDataValue, useDataChange } from 'Simple/Data'

export function useOnClick(props) {
  let current_date = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected.date',
  })

  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected.date',
  })

  return function onClick(value) {
    if (value.target.id === 'prev') {
      change(subMonths(current_date, 1))
    } else if (value.target.id === 'forward') {
      change(addMonths(current_date, 1))
    }
  }
}
