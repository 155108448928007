// this is an autogenerated file from App/Account/Content/Calendar/New/AppointmentOverlay/Content/Form/Content/AppointmentSelect/Content/Popup/Content/Unscheduled/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_AppointmentOverlay_Content_Form_Content_AppointmentSelect_Content_Popup_Content_Unscheduled($patient_id: uuid!) {
  appointments_upcoming_unscheduled_appointments_by_patient(
    args: { patient_id: $patient_id }
  ) {
    id: _id
    duration
    note
    tx {
      id: _id
      tx_card {
        id: _id
        name
      }
    }
    type {
      id: _id
      full_name
    }
    patient {
      id: _id
    }
  }
}

`