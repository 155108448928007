import React, { useEffect, useRef } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import useIsHovered from 'Simple/hooks/useIsHovered.js'
import { useFloating, offset, flip } from '@floating-ui/react-dom'
import View from './view.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  let popoverData = useDataValue({
    context: 'popover',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'popover',
    viewPath: props.viewPath,
  })

  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: props.disabled,
    isSelected: false,
  })

  useEffect(() => {
    if (hovered) {
      change(next => {
        next.isHoverOpen =
          props.disabled !== true &&
          !popoverData.isLeftClickOpen &&
          !popoverData.isRightClickOpen &&
          props.showOnHover
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hovered,
    popoverData.isLeftClickOpen,
    popoverData.isRightClickOpen,
    props.showOnHover,
    props.disabled,
  ])

  let triggerRef = useRef()

  let { refs, x, y, elements, update } = useFloating({
    open:
      popoverData.isLeftClickOpen ||
      popoverData.isRightClickOpen ||
      popoverData.isHoverOpen,
    placement: props.placement || 'bottom-start',
    strategy: 'fixed',
    middleware: [
      flip(),
      offset({
        crossAxis: props.offsetCrossAxis,
        mainAxis: props.offsetMainAxis ?? 12,
      }),
    ],
  })

  useOnClickOutside(refs.floating, event => {
    if (
      event.target === triggerRef.current ||
      triggerRef.current?.contains(event.target)
    ) {
      return
    }

    change(next => {
      next.isLeftClickOpen = false
      next.isRightClickOpen = false
    })
  })

  return (
    <View
      {...props}
      hovered={hovered}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
      isOpen={
        popoverData.isLeftClickOpen &&
        !popoverData.isRightClickOpen &&
        props.showOnClick !== false
      }
      isHoverOpen={popoverData.isHoverOpen}
      isRightClickOpen={
        !popoverData.isLeftClickOpen &&
        popoverData.isRightClickOpen &&
        props.showOnRightClick
      }
      onLeftClick={onLeftClick}
      onRightClick={onRightClick}
      toggle={toggle}
      triggerRef={triggerRef}
      parentRef={refs.setReference}
      contentRef={refs.setFloating}
      left={x}
      top={y}
      width={props.width ?? elements.reference?.getBoundingClientRect()?.width}
    />
  )

  function onLeftClick(event) {
    /**
     * Using `!props.showOnClick` instead of `props.showOnClick === false`
     * as it would filter out the condition of `false` and `undefined`
     * both. However, we want `showOnClick = undefined` to be treated as
     * `true` to be backward compatible with the previous implementation
     * of the component
     */
    if (props.disabled || props.showOnClick === false) return

    event.preventDefault()
    change(next => {
      next.isLeftClickOpen = !popoverData.isLeftClickOpen
      next.isRightClickOpen = false
    })
    update()
  }

  function onRightClick(event) {
    if (props.disabled || !props.showOnRightClick) return

    event.preventDefault()
    change(next => {
      next.isRightClickOpen = !popoverData.isRightClickOpen
      next.isLeftClickOpen = false
    })
    update()
  }

  function toggle() {
    change(next => {
      next.isLeftClickOpen = !popoverData.isLeftClickOpen
    })
  }
}
