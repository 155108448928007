import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useNotifications } from 'Logic/Notifications'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let NEXT_STEP_ACTION = {
    selection: 'schedule',
    schedule: 'setup',
    setup: 'sign_contract',
    sign_contract: 'setup',
  }

  let BACK_STEP_ACTION = {
    schedule: 'selection',
    setup: 'schedule',
    sign_contract: 'setup',
  }
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'continue': {
        change(next => {
          if (next.current_step === 'sign_contract') {
            next.completed = true
          }
          next.current_step =
            NEXT_STEP_ACTION[next.current_step] ?? next.current_step
        })
        break
      }
      case 'back': {
        change(next => {
          if (next.current_step === 'sign_contract') {
            next.completed = false
          }

          next.current_step =
            BACK_STEP_ACTION[next.current_step] ?? next.current_step
        })
        break
      }
      case 'cancel': {
        setFlowTo(normalizePath(props.viewPath, '../../../TreatmentCard'))
        break
      }
      case 'done': {
        setFlowTo(normalizePath(props.viewPath, '../../../TreatmentCard'))
        break
      }
      default: {
        break
      }
    }
  }
}
