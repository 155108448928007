import { useMemo } from 'react'
import { NOTE_TEMPLATE_TAGS } from 'Data/constants.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    return Object.keys(NOTE_TEMPLATE_TAGS).map(key => ({
      id: key,
      text: NOTE_TEMPLATE_TAGS[key],
    }))
  }, [])
}
