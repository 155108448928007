import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'

import mutation_update from './mutation_update.graphql.js'
import mutation_delete from './mutation_delete.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props) {
  let onUpdate = useOnUpdate(props)
  let onDelete = useOnDelete(props)

  return async function onSubmit({ value, args }) {
    switch (args.type) {
      case 'delete':
        return onDelete(value)
      default:
        return onUpdate(value)
    }
  }
}

function useOnUpdate(props) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation_update)

  return async function onSubmit(value) {
    let mutationResponse = await executeMutation({
      note_id: value.id,
      new_note: value.note,
    })
    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }

    notify(notifySuccess('Note updated!'))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}

export function useOnDelete(props) {
  let changeEvents = useDataChange({
    viewPath: props.viewPath,
    context: 'events',
  })
  let events = useDataValue({
    viewPath: props.viewPath,
    context: 'events',
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation_delete)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onDelete(value) {
    if (!window.confirm('Are you sure you want to delete this note?')) return

    let mutationResponse = await executeMutation({
      note_id: value.id,
    })
    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
    notify(notifySuccess('Note deleted!'))
    changeEvents(events.filter(e => e?.note?.id !== value.id))
    setFlowTo(normalizePath(props.viewPath, '../../../No'))
  }
}
