// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/FeatureFlags/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_FeatureFlags{
  vaxiom_sys_organizations(
    order_by: { name: asc }
    where: { level: { _in: ["location", "parent"] } }
  ) {
    id
    _id
    name
    level
    application_properties(
      where: {
        message_key: {
          _in: [
            "new.profile.enabled"
            "new.profile.default.enabled"
            "new.tx.card.default.enabled"
            "new.reporting.default.enabled"
            "new.apps.enabled"
            "new.statements.enabled"
            "new.statements.default.enabled"
            "new.payments.enabled"
            "new.automation-hub.enabled"
            "new.marketing-analytics.enabled"

            # "new.calendar.enabled",
            # "new.calendar.default.enabled",

            # "admin.patient.discussion.enabled",
            # "admin.patient.videos.enabled",

            # "audax.ceph.enabled",
            # "dental_monitoring.enabled"
            # "medical-form.v2.disable_insurance_questions"
            # "medical.form.v2.appointment.enabled"
          ]
        }
      }
    ) {
      message_key
      message_value
      organization_id
    }
  }
}

`