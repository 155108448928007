import { snakeCaseToHumanReadable } from 'App/Account/Content/PatientsList/helpers.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data || !data.length) return data

    let array = data.map(item => ({
      id: item.text,
      text: snakeCaseToHumanReadable(item.text),
    }))

    return [
      ...array,
      {
        id: 'na',
        text: 'New Patient - No Tx',
      },
    ]
  }, [data])
}
