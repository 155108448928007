import { useDataValue, useDataChange } from 'Simple/Data'
import { useEffect } from 'react'

export function useOnClick(props) {
  let isFloating = useDataValue({
    context: 'tab',
    path: 'sidebar.isFloating',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  let isFloatingAndVisible = useDataValue({
    context: 'tab',
    path: 'sidebar.isFloatingAndVisible',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!isFloatingAndVisible && props.isHovered)
      change(next => {
        next.sidebar.isFloatingAndVisible = true
      })
  }, [isFloatingAndVisible, props.isHovered, change])

  return function onClick() {
    change(next => {
      next.sidebar.isFloating = !isFloating
    })
  }
}
