import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'appointmentOverlay.patient_id',
  })
  let submitGlobal = useDataSubmit({
    viewPath: props.viewPath,
    context: 'global',
  })
  let submitTab = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return () => {
    submitGlobal({
      type: 'patientOverlay/open',
      patient_id,
    })
    submitTab({
      type: 'appointmentOverlay/close',
    })
  }
}
