import { useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnChange(props) {
  let hipaa = useDataValue({
    context: 'tab',
    path: 'selected.hipaa_active',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'settings',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      key: 'hipaa',
      value: !hipaa,
    })
  }
}
