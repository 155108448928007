import { useDataValue, useDataSubmit } from 'Simple/Data'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnSubmit(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let id = useDataValue({
    context: 'note_template',
    path: 'id',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'note_templates_tab',
    viewPath: props.viewPath,
  })

  return function onSubmit(value) {
    submit({ type: 'copy', locations: value, id })
    setFlowTo(normalizePath(props.viewPath, '../../../No'))
  }
}
