// this is an autogenerated file from DesignSystem/ManualAutomations/Content/ActiveAction/TaskInsert/DocumentTemplate/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ManualAutomations_Content_ActiveAction_TaskInsert_DocumentTemplate($organization_id: numeric!) {
  vaxiom_document_templates(
    where: { organization_id: { _eq: $organization_id } }
    order_by: { name: asc }
  ) {
    id
    text: name
  }
}

`