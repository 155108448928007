import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(
    () => [
      {
        type: 'Invisalign',
        plans: [
          {
            id: 1,
            name: 'Comprehensive Orthodontics Adult',
            length: 3,
            fee: 7200,
          },
          {
            id: 2,
            name: 'Comprehensive orthodontic treatment with carriere',
            length: 4,
            fee: 5400,
          },
          {
            id: 3,
            name: 'Comprehensive Orthodontics Aligners - Child',
            length: 4,
            fee: 6300,
          },
          {
            id: 4,
            name: 'Comprehensive orthodontic treatment with hyrax',
            length: 12,
            fee: 3500,
          },
        ],
      },
      {
        type: 'LightForce',
        plans: [
          { id: 5, name: 'CBS Long', length: 3, fee: 6200 },
          {
            id: 6,
            name: 'COMP Adult 24 M',
            length: 4,
            fee: 7200,
          },
          { id: 7, name: 'CBS Long', length: 4, fee: 7200 },
        ],
      },
    ],
    []
  )
}
