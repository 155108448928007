export function useOnClickArrowLeft(props) {
  return () => {
    window.alert('left')
  }
}

export function useOnClickArrowRight(props) {
  return () => {
    window.alert('right')
  }
}
