// this is an autogenerated file from DesignSystem/ManualAutomations/Content/ActiveAction/StatusUpdate/Content/SectionText/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ManualAutomations_Content_ActiveAction_StatusUpdate_Content_SectionText($patient_id: numeric!) {
  vaxiom_patients_by_pk(id: $patient_id) {
    id
    person {
      id
      first_name
      last_name
    }
  }
}

`