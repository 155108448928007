import { useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'tabs/add',
      tab: {
        type: 'Reports',
        id: 'Reports',
        name: 'Reports',
        viewPath: '/App/Account/Content/Insights/Reports',
      },
    })
  }
}
