import React, { useEffect, useRef, useState, useMemo } from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import scrollgrid from '@fullcalendar/scrollgrid'
import './style.css'

import Resource from './Resource/index.js'
import SlotLabel from './SlotLabel/index.js'
import Appointment from './Appointment/index.js'
import AppointmentCandidate from './AppointmentCandidate/index.js'
import Note from './Note/index.js'
import BlockedTime from './BlockedTime/index.js'
import AppointmentType from './AppointmentType/index.js'
import Closed from './Closed/index.js'
import SlotDialog from './SlotDialog/index.js'

import { formatISO } from 'date-fns'
import { twoDigitsTime } from 'Data/format.js'
import { DataProvider } from 'Simple/Data.js'
import { utcToZonedTime } from 'Data/date.js'

export default function FullCalendarTimegrid({
  events,
  resources,
  date,
  timeZoneId,
  viewPath,
  scrollTime,
  slotMinTime,
  slotMaxTime,
  slotInterval = 10,
  slotLabelMinutes = 10,
}) {
  let [showSlotDialog, setShowSlotDialog] = useState(false)
  let slotDialogElementId = 'slot-dialog'
  let calendarRef = useRef(null)
  let slotRef = useRef({
    position: null,
    start: null,
    end: null,
    resourceId: null,
  })

  let currentDate = useMemo(
    () => formatISO(utcToZonedTime(new Date().toISOString(), timeZoneId)),
    [timeZoneId]
  )
  let slotDuration = useMemo(
    () => `00:${twoDigitsTime(slotInterval)}:00`,
    [slotInterval]
  )

  function renderEventContent(value) {
    switch (value.event.extendedProps.type) {
      case 'appointment':
        return (
          <DataProvider
            context="event"
            value={{
              id: value.event.id,
              title: value.event.title,
              start: value.event.startStr,
              end: value.event.endStr,
              ...value.event.extendedProps,
            }}
            viewPath={viewPath}
          >
            <Appointment
              viewPath={`${viewPath}/Appointment(${value.event.id})`}
            ></Appointment>
          </DataProvider>
        )
      case 'note':
        return (
          <DataProvider
            context="event"
            value={{
              id: value.event.id,
              content: value.event.extendedProps?.content,
              start: value.event.start,
              end: value.event.end,
            }}
            viewPath={viewPath}
          >
            <Note viewPath={`${viewPath}/Note(${value.event.id})`}></Note>
          </DataProvider>
        )
      case 'block':
        return (
          <DataProvider
            context="event"
            value={{
              id: value.event.id,
              content: value.event.extendedProps?.content,
              start: value.event.start,
              end: value.event.end,
            }}
            viewPath={viewPath}
          >
            <BlockedTime
              viewPath={`${viewPath}/BlockedTime(${value.event.id})`}
            ></BlockedTime>
          </DataProvider>
        )
      case 'candidate':
        return (
          <DataProvider
            context="event"
            value={{
              id: value.event.id,
              title: value.event.title,
              start: value.event.start,
              end: value.event.end,
              ...value.event.extendedProps,
            }}
            viewPath={viewPath}
          >
            <AppointmentCandidate
              viewPath={`${viewPath}/AppointmentCandidate(${value.event.id})`}
            ></AppointmentCandidate>
          </DataProvider>
        )
      case 'appointment-type':
        return (
          <DataProvider
            context="event"
            value={{
              id: value.event.id,
              title: value.event.title,
              start: value.event.start,
              end: value.event.end,
              ...value.event.extendedProps,
            }}
            viewPath={viewPath}
          >
            <AppointmentType
              viewPath={`${viewPath}/AppointmentType(${value.event.id})`}
            ></AppointmentType>
          </DataProvider>
        )
      case 'closed':
        return (
          <DataProvider
            context="event"
            value={{
              id: value.event.id,
              start: value.event.start,
              end: value.event.end,
            }}
            viewPath={viewPath}
          >
            <Closed viewPath={`${viewPath}/Closed(${value.event.id})`}></Closed>
          </DataProvider>
        )
      default:
        return null
    }
  }
  function renderResourceContent(value) {
    return (
      <Resource
        viewPath={viewPath}
        {...value.resource.extendedProps}
      ></Resource>
    )
  }
  function renderSlotLabelContent(value) {
    let date = new Date(value.date)
    let minutes = date.getMinutes()
    let hours, range, mins
    if (minutes === 0) {
      hours = value.text.match(/\d+/)[0]
      range = value.text.match(/[a-zA-Z]+/)[0]
      mins = '00'
    } else {
      mins = twoDigitsTime(minutes)
    }

    return (
      <SlotLabel
        viewPath={viewPath}
        hours={hours}
        range={range}
        minutes={mins}
      ></SlotLabel>
    )
  }
  function onSelectSlot(value) {
    slotRef.current = {
      position: value.jsEvent.target.getBoundingClientRect(),
      start: value.startStr,
      end: value.endStr,
      resourceId: value.resource.id,
    }
  }
  function onUnselectSlot() {
    slotRef.current = {
      position: null,
      start: null,
      end: null,
      resourceId: null,
    }
    setShowSlotDialog(false)
  }

  // Update now indicator label
  useEffect(() => {
    function updateTimeIndicator() {
      let nowIndicator = document.querySelector(
        '.fc-timegrid-now-indicator-arrow'
      )
      if (nowIndicator) {
        let nowTime = utcToZonedTime(new Date().toISOString(), timeZoneId)
        let hours = twoDigitsTime(nowTime.getHours())
        let minutes = twoDigitsTime(nowTime.getMinutes())
        let timeLabel = document.querySelector(
          '.fc-timegrid-now-indicator-time'
        )
        if (!timeLabel) {
          timeLabel = document.createElement('div')
          timeLabel.className = 'fc-timegrid-now-indicator-time'
          nowIndicator.appendChild(timeLabel)
        }
        timeLabel.textContent = `${hours}:${minutes}`
      }
    }

    updateTimeIndicator()
    let interval = setInterval(updateTimeIndicator, 1000)
    return () => clearInterval(interval)
  }, [])
  // Update calendar date managed from outside
  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(date)
    }
  }, [date])
  // Manage right click on empty slot
  useEffect(() => {
    function handleRightClick(event) {
      event.preventDefault()
      if (event.target.classList.contains('fc-highlight')) {
        setShowSlotDialog(true)
      }
    }

    let calendarElement = calendarRef.current.getApi().el
    calendarElement.addEventListener('contextmenu', handleRightClick)
    return () => {
      calendarElement.removeEventListener('contextmenu', handleRightClick)
    }
  }, [])

  return (
    <div className="calendar-container">
      <FullCalendar
        ref={calendarRef}
        timeZone={timeZoneId}
        plugins={[
          timeGridPlugin,
          interactionPlugin,
          resourceTimeGridPlugin,
          scrollgrid,
        ]}
        initialView="resourceTimeGridDay"
        editable={true} // Enables event edition - for example, drag and drop - for the calendar
        headerToolbar={null} // Hides toolbar with today, next, previous actions
        events={events}
        resourceOrder={'position'}
        resources={resources}
        now={currentDate} // Current date
        eventContent={renderEventContent} // Custom event component
        resourceLabelContent={renderResourceContent} // Custom resource component
        allDaySlot={false} // Display specific slot/row below calendar header for all-day events
        slotMinTime={slotMinTime} // Earliest time to display on the time axis
        slotMaxTime={slotMaxTime} // Latest time to display on the time axis
        slotDuration={slotDuration} // Slot duration in minutes
        slotLabelInterval={{ minutes: slotLabelMinutes }} // Minutes in which the slot label is displayed
        slotLabelContent={renderSlotLabelContent} // Custom label to render for every slot
        nowIndicator={true} // Line that indicates the current time
        scrollTime={scrollTime} // Initial scroll position of the timegrid when it is first rendered
        slotMinWidth={167}
        dayMinWidth={167}
        height={'100%'}
        eventResourceEditable={false}
        selectable={true}
        selectOverlap={false}
        select={onSelectSlot}
        unselect={onUnselectSlot}
        unselectCancel={`#${slotDialogElementId}`}
      />
      <SlotDialog
        viewPath={`${viewPath}/SlotDialog`}
        slotDialogElementId={slotDialogElementId}
        showDialog={showSlotDialog}
        position={slotRef.current.position}
        start={slotRef.current.start}
        end={slotRef.current.end}
        resourceId={slotRef.current.resourceId}
        onClose={() => setShowSlotDialog(false)}
      ></SlotDialog>
    </div>
  )
}
