import { useDataChange, useDataValue } from 'Simple/Data.js'
import { age, dateShortIn } from 'Data/format.js'

export function format(person, hipaa) {
  /** @type {(string | number)[]} */
  let result = [
    [person.first_name, !hipaa && person.last_name].filter(Boolean).join(' '),
  ]

  if (person.gender) result.push(person.gender[0])

  if (person.birth_date) {
    result.push(age(person.birth_date), dateShortIn(person.birth_date))
  }

  return result.filter(Boolean).join(', ')
}

export function useOnClick(props) {
  let change = useDataChange({
    context: 'global',
    viewPath: props.viewPath,
  })

  let patient_id = useDataValue({
    context: 'event',
    viewPath: props.viewPath,
    path: 'appointment.patient._id',
  })

  let hipaa = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected.hipaa_active',
  })

  return function onClick() {
    change(next => {
      next.patient_overlay_patient_id = patient_id
      next.patient_overlay_hipaa_active = hipaa
    })
  }
}
