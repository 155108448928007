import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(i => ({
      id: i.id,
      text: i.tx_card.name,
    }))
  }, [data])
}
