import { useMemo } from 'react'
import uuid from 'uuid/v4.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    return {
      ...data,
      allow_reuse:
        typeof props.allowReuse === 'boolean'
          ? props.allowReuse
          : data.allow_reuse,
      allow_location_choice:
        typeof props.allowLocationChoice === 'boolean'
          ? props.allowLocationChoice
          : data.allow_location_choice,
      xid: props.xid,
      containerId: `${data.containerId}-${uuid()}`,
    }
  }, [data, props.allowReuse, props.allowLocationChoice, props.xid])
}
