// this is an autogenerated file from DesignSystem/ManualAutomations/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__DesignSystem_ManualAutomations($user_id: uuid!) {
  events_action_executions(
    where: {
      status: { _eq: pending }
      event_execution: { user_id: { _eq: $user_id } }
      action: { event: { status: { _eq: active } } }
    }
  ) {
    id
    status
    data
    is_required
    event_execution {
      id
      data
    }
    action {
      id
      type
      data
      event {
        id
        type
        trigger_type
        data
      }
    }
  }
}

`