import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import isBefore from 'date-fns/isBefore'
import isEqual from 'date-fns/isEqual'
import _formatNumber from 'number-format.js'
import toSentenceCase from 'to-sentence-case'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import CreditCardMasterCard from 'design-system/Svgs/CreditCardMasterCard/source.svg?raw'
import CreditCardVisa from 'design-system/Svgs/CreditCardVisa/source.svg?raw'
import CreditCardDiscover from 'design-system/Svgs/CreditCardDiscover/source.svg?raw'
import CreditCardAmex from 'design-system/Svgs/CreditCardAmex/source.svg?raw'

/**
 * Function to create a PDF statement.
 * @param {{
 *   pdfMake: import('pdfmake/build/pdfmake'),
 *   statement: any,
 *   location: any,
 *   credit_cards: string[]
 * }} params - Parameters for creating the PDF.
 */
export default function createStatementPdf({
  statement,
  location,
  credit_cards,
  pdfMake,
}) {
  let postal_address =
    statement.payer_person.contact_method_associations[0]?.postal_address
  let buckets = { DueNow: 0, Over30: 0, Over60: 0, Over90: 0 }
  let totalPayments = 0
  statement.due_receivables.forEach(item => {
    if (typeof item.total_payments === 'number') {
      totalPayments += item.total_payments
    }
    let diff = differenceInCalendarDays(new Date(), parseISO(item.due_date))
    if (diff < 30) {
      buckets.DueNow += item.due_now_amount
    } else if (diff < 60) {
      buckets.Over30 += item.due_now_amount
    } else if (diff < 90) {
      buckets.Over60 += item.due_now_amount
    } else {
      buckets.Over90 += item.due_now_amount
    }
  })

  let transactions = [...statement.due_receivables]
    .sort((a, b) => {
      let aDueDate = parseISO(a.due_date)
      let bDueDate = parseISO(b.due_date)
      return isEqual(aDueDate, bDueDate)
        ? 0
        : isBefore(aDueDate, bDueDate)
        ? -1
        : 1
    })
    .map(ritem =>
      ritem.transactions.map(titem => [
        { text: format(parseISO(ritem.due_date), 'MM/dd/yyyy') },
        { text: format(parseISO(titem.effective_date), 'MM/dd/yyyy') },
        { text: `#${ritem.human_id}` },
        {
          text: `${toSentenceCase(titem.dtype)}${
            titem.human_id ? ` #${titem.human_id}` : ''
          }`,
        },
        { text: format(parseISO(ritem.due_date), 'MM/dd/yyyy') },
        {
          text: titem.amount > 0 ? numberMoney(titem.amount) : numberMoney(0),
          alignment: 'right',
        },
        {
          text:
            titem.amount > 0
              ? numberMoney(0)
              : numberMoney(Math.abs(titem.amount)),
          alignment: 'right',
        },
        { text: numberMoney(ritem.due_now_amount), alignment: 'right' },
      ])
    )
    .flat()

  /** @type {import('pdfmake/interfaces.js').Content} */
  let content = [
    {
      columns: [
        {
          width: '65%',
          stack: [
            {
              margin: [24, 0, 0, 0],
              stack: [
                { text: location.parent.name, bold: true },
                { text: location.postal_address.address_line1 },
                {
                  text: `${location.postal_address.city}, ${location.postal_address.state} ${location.postal_address.zip}`,
                },
              ],
            },

            { text: 'BILL TO:', bold: true, margin: [0, 24, 0, 0] },

            {
              margin: [24, 12, 0, 0],
              stack: [
                {
                  text: `${statement.payer_person.first_name} ${statement.payer_person.last_name}`,
                  bold: true,
                },
                { text: postal_address ? postal_address.address_line1 : '' },
                {
                  text: postal_address
                    ? [
                        postal_address.address_line2,
                        `${postal_address.city}, ${postal_address.state} ${postal_address.zip}`,
                      ]
                        .filter(Boolean)
                        .join(' ')
                    : '',
                },
              ],
            },
          ],
        },
        {
          stack: [
            {
              margin: [0, -24, 0, 0],
              alignment: 'center',
              image: location.logo.url,
              fit: [100, 150],
            },
            {
              margin: [0, 12, 0, 0],
              columns: [
                {
                  width: 100,
                  margin: [0, 3, 4, 0],
                  text: 'Statement date',
                  bold: true,
                },
                {
                  table: {
                    widths: [65],
                    body: [
                      [
                        {
                          text: format(
                            parseISO(statement.created_at),
                            'MM/dd/yyyy'
                          ),
                        },
                      ],
                    ],
                  },
                },
              ],
            },
            {
              margin: [0, 2, 0, 0],

              columns: [
                {
                  width: 100,
                  margin: [0, 3, 4, 0],
                  text: 'Amount due now',
                  bold: true,
                },
                {
                  table: {
                    widths: [65],
                    body: [
                      [
                        {
                          text: numberMoney(statement.due_now_amount),
                          alignment: 'right',
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },

    {
      margin: [0, 24, 0, 0],
      layout: 'noBorders',
      fontSize: 10,
      table: {
        widths: ['*', '*', '*', '*', '*', '*'],
        body: [
          [
            { text: 'Account balance', style: 'tableHeader' },
            { text: '0-30 days', style: 'tableHeader' },
            { text: 'Over 30 days', style: 'tableHeader' },
            { text: 'Over 60 days', style: 'tableHeader' },
            { text: 'Over 90 days', style: 'tableHeader' },
            { text: 'Total payments', style: 'tableHeader' },
          ],
          [
            {
              text: numberMoney(statement.account_balance),
              style: 'tableCell',
            },
            { text: numberMoney(buckets.DueNow), style: 'tableCell' },
            { text: numberMoney(buckets.Over30), style: 'tableCell' },
            { text: numberMoney(buckets.Over60), style: 'tableCell' },
            { text: numberMoney(buckets.Over90), style: 'tableCell' },
            { text: numberMoney(totalPayments), style: 'tableCell' },
          ],
        ],
      },
    },
  ]

  /**
   * The following while loop helps to display the
   * transactions and spread them through multiple
   * pages, as needed. There are 4 cases that we need
   * to account for:
   * 1. If the page is a blank one, then we display
   *    the most number of transactions that we can
   * 2. If the page has the initial block of data,
   *    containing the patient information and account
   *    overview
   * 3. If the current page would be the last one and
   *    should contain the credit card slip information
   * 4. This is a special case, when the number of
   *    transactions are such that the initial block and
   *    the credit card slip can be displayed in the
   *    same page
   */
  let isFirstPage = true
  while (
    !(
      (isFirstPage &&
        transactions.length <=
          MAXIMUM_TRANSACTIONS_ON_PAGE_WITH_TWO_INFO_BLOCKS) ||
      (!isFirstPage &&
        transactions.length <= MAXIMUM_TRANSACTIONS_ON_PAGE_WITH_ONE_INFO_BLOCK)
    )
  ) {
    let transactionsOnThisPage = transactions.slice(
      0,
      isFirstPage
        ? MAXIMUM_TRANSACTIONS_ON_PAGE_WITH_ONE_INFO_BLOCK
        : MAXIMUM_TRANSACTIONS_ON_BLANK_PAGE
    )
    if (!isFirstPage) {
      content.push({ text: '', pageBreak: 'before' })
    }

    content.push({
      margin: [0, 24, 0, 0],
      fontSize: 10,
      layout: {
        hLineColor: '#F5F7FA',
        vLineColor: '#F5F7FA',
      },
      table: {
        widths: ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'],
        headerRows: 2,
        body: [
          [
            { text: 'Account activity', style: 'tableHeader', colSpan: 8 },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ],
          [
            { text: 'Invoice date', style: 'tableHeader' },
            { text: 'Transaction date', style: 'tableHeader' },
            { text: 'Invoice #', style: 'tableHeader' },
            { text: 'Description', style: 'tableHeader' },
            { text: 'Due date', style: 'tableHeader' },
            { text: 'Amount', style: 'tableHeader' },
            { text: 'Credits', style: 'tableHeader' },
            { text: 'Invoice balance', style: 'tableHeader' },
          ],
          ...transactionsOnThisPage,
        ],
      },
    })
    transactions = transactions.slice(transactionsOnThisPage.length)
    isFirstPage = false
  }

  if (transactions.length === 0)
    content = content.concat([
      {
        margin: [0, 24, 0, 0],
        canvas: [
          {
            type: 'line',
            x1: -20,
            y1: 0,
            x2: 600,
            y2: 0,
            dash: { length: 5, space: 6 },
          },
        ],
      },
      { text: '', pageBreak: 'before' },
    ])

  if (transactions.length > 0) {
    // If there are transactions to show in the last page
    if (!isFirstPage) {
      content.push({ text: '', pageBreak: 'before' })
    }
    content = content.concat([
      {
        margin: [0, 24, 0, 0],
        fontSize: 10,
        layout: {
          hLineColor: '#F5F7FA',
          vLineColor: '#F5F7FA',
        },
        table: {
          widths: ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'],

          headerRows: 2,
          body: [
            [
              { text: 'Account activity', style: 'tableHeader', colSpan: 8 },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              { text: 'Invoice date', style: 'tableHeader' },
              { text: 'Transaction date', style: 'tableHeader' },
              { text: 'Invoice #', style: 'tableHeader' },
              { text: 'Description', style: 'tableHeader' },
              { text: 'Due date', style: 'tableHeader' },
              { text: 'Amount', style: 'tableHeader' },
              { text: 'Credits', style: 'tableHeader' },
              { text: 'Invoice balance', style: 'tableHeader' },
            ],
            ...transactions,
          ],
        },
      },
      {
        margin: [0, 24, 0, 0],
        canvas: [
          {
            type: 'line',
            x1: -20,
            y1: 0,
            x2: 600,
            y2: 0,
            dash: { length: 5, space: 6 },
          },
        ],
      },
    ])
  }

  // Credit card slip
  content.push({
    margin: [0, 24, 0, 0],
    absolutePosition: { x: 24, y: transactions.length > 0 ? 520 : 48 },
    columns: [
      {
        stack: [
          {
            margin: [24, 0, 0, 0],
            stack: [
              {
                text: `${statement.payer_person.first_name} ${statement.payer_person.last_name}`,
                bold: true,
              },
              { text: postal_address ? postal_address.address_line1 : '' },
              {
                text: postal_address
                  ? [
                      postal_address.address_line2,
                      `${postal_address.city}, ${postal_address.state} ${postal_address.zip}`,
                    ]
                      .filter(Boolean)
                      .join(' ')
                  : '',
              },
            ],
          },

          {
            text: 'MAKE CHECKS PAYABLE TO:',
            bold: true,
            margin: [0, 24, 0, 0],
          },

          {
            margin: [24, 12, 0, 0],

            stack: [
              { text: location.name, bold: true },
              { text: location.postal_address.address_line1 },
              {
                text: `${location.postal_address.city}, ${location.postal_address.state} ${location.postal_address.zip}`,
              },
            ],
          },
        ],
      },

      {
        width: '55%',
        stack: [
          {
            layout: {
              hLineColor: '#F5F7FA',
              vLineColor: '#F5F7FA',
            },
            fontSize: 10,
            table: {
              widths: ['auto', '*', 'auto', 'auto'],
              body: [
                [
                  { text: 'Patient ID', style: 'tableHeader' },
                  { text: 'Patient name', style: 'tableHeader' },
                  { text: 'Amount', style: 'tableHeader' },
                  { text: 'Amount enclosed', style: 'tableHeader' },
                ],
                [
                  {
                    text: `#${statement.patient.human_readable_id}`,
                    style: 'tableCell',
                  },
                  {
                    text: `${statement.patient.person.first_name} ${statement.patient.person.last_name}`,
                    style: 'tableCell',
                  },
                  {
                    text: numberMoney(statement.due_now_amount),
                    style: 'tableCell',
                  },
                  { text: '', style: 'tableCell' },
                ],
              ],
            },
          },
          {
            margin: [0, 12, 0, 0],
            text: `If paying with ${toSentenceWithChoices(
              credit_cards
            )}, fill out below.`,
            fontSize: 10,
          },
          {
            margin: [0, 4, 0, 0],
            fontSize: 10,
            table: {
              body: [
                [
                  credit_cards.includes('Visa')
                    ? {
                        svg: CreditCardVisa,
                        fit: [25, 25],
                        alignment: 'center',
                        colSpan: 2,
                      }
                    : { text: '', colSpan: 2 },
                  {},
                  credit_cards.includes('MasterCard')
                    ? {
                        svg: CreditCardMasterCard,
                        fit: [25, 25],
                        alignment: 'center',
                        colSpan: 2,
                      }
                    : { text: '', colSpan: 2 },
                  {},
                  credit_cards.includes('Amex')
                    ? {
                        svg: CreditCardAmex,
                        fit: [25, 25],
                        alignment: 'center',
                        colSpan: 2,
                      }
                    : { text: '', colSpan: 2 },
                  {},
                  credit_cards.includes('Discover')
                    ? {
                        svg: CreditCardDiscover,
                        fit: [25, 25],
                        alignment: 'center',
                        colSpan: 2,
                      }
                    : { text: '', colSpan: 2 },
                  {},
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Card Number',
                      },
                      {
                        text: '     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     |     ',
                        fontSize: 12,
                      },
                    ],
                    colSpan: 8,
                  },
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                ],
                [
                  {
                    text: 'Signature:',
                    colSpan: 5,
                  },
                  {},
                  {},
                  {},
                  {},
                  {
                    text: 'Exp. Date:',
                    colSpan: 3,
                  },
                  {},
                  {},
                ],
                [
                  {
                    text: 'Statement Date:',
                    colSpan: 3,
                  },
                  {},
                  {},
                  {
                    text: 'CVV/CID:',
                    colSpan: 2,
                  },
                  {},
                  {
                    text: 'Zip Code:',
                    colSpan: 3,
                  },
                  {},
                  {},
                ],
                [
                  {
                    text: 'Pay This Amount',
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},
                  {
                    stack: [
                      {
                        text: 'Show Amount ',
                      },
                      {
                        text: 'Paid Here $',
                      },
                    ],
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},
                ],
              ],
            },
          },
        ],
      },
    ],
  })

  return pdfMake.createPdf({
    content,
    styles: {
      tableHeader: {
        bold: true,
        alignment: 'center',
        fillColor: '#E4E7EB',
      },
      tableCell: {
        alignment: 'center',
      },
    },
    defaultStyle: {},
    footer: function (currentPage, pageCount) {
      return [
        {
          text: `Page ${currentPage} of ${pageCount}`,
          italics: true,
          alignment: 'right',
          margin: [0, 0, 24, 0],
          fontSize: 8,
        },
      ]
    },
    pageSize: 'LETTER',

    // by default we use portrait, you can change it to landscape if you wish
    pageOrientation: 'portrait',

    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [24, 48],
  })
}

// duplicating the helper here because Data/format.js has too many things
// that break the web worker
function formatNumber(value, format) {
  return typeof value === 'number' ? _formatNumber(format, value) : value
}

function numberMoney(value) {
  return formatNumber(value, '$#,##0.00')
}

function toSentenceWithChoices(list) {
  return list.length > 1
    ? list.slice(0, -1).join(', ') + ', or ' + list.slice(-1)
    : list[0]
}

/**
 * If a page would display only a list of transactions,
 * then this value determines how many we might fit into it
 */
let MAXIMUM_TRANSACTIONS_ON_BLANK_PAGE = 35

/**
 * By one info block, it means that either the introductory
 * block where the patient details and account overview is
 * included, or the credit card slip details included at the
 * bottom of a page
 */
let MAXIMUM_TRANSACTIONS_ON_PAGE_WITH_ONE_INFO_BLOCK = 24

/**
 * If the number of transactions are within the below value,
 * then both the blocks of data:
 * 1. patient details and account overview at the starting
 * 2. credit card slip at the bottom
 * would easily fit into the same page
 */
let MAXIMUM_TRANSACTIONS_ON_PAGE_WITH_TWO_INFO_BLOCKS = 10
