// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Appointments/TabContent/TreatmentPlanFilter/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_PatientOverlay_Content_Content_Appointments_TabContent_TreatmentPlanFilter($patient_id: numeric!) {
  vaxiom_txs(
    where: {
      tx_card: { patient_id: { _eq: $patient_id }, deleted: { _eq: false } }
      deleted: { _eq: false }
    }
    order_by: {
      tx_card: { latest_appointment_booking_start_time: desc_nulls_last }
    }
  ) {
    id: _id
    text: name
    tx_card {
      id
      _id
      name
      latest_appointment_booking_start_time
    }
  }
}

`