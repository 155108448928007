import { utcToZonedTime } from 'date-fns-tz'
import { useDataChange } from 'Simple/Data'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.selected.date = utcToZonedTime(
        new Date(),
        next.selected.time_zone_id
      )
    })
  }
}
