/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'appointmentOverlay/open': {
        change(next => {
          // reset previous state, it will go into No
          next.appointmentOverlay.open = false
        })
        // setTimeout to prevent batching of change calls
        setTimeout(() => {
          change((next, x) => {
            next.appointmentOverlay = {
              open: true,
              appointment_id: null,
              patient_id: null,
            }
          })
        })
        break
      }
      case 'appointmentOverlay/close': {
        change(next => {
          next.appointmentOverlay = {
            open: false,
            appointment_id: null,
            patient_id: null,
          }
        })
        break
      }
      default: {
        break
      }
    }
  }
}
