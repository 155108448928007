import React, { useEffect } from 'react'

import { useDataChange, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let app_id = useDataValue({
    context: 'connection',
    path: 'app_id',
    viewPath: props.viewPath,
  })
  let setAppId = useDataChange({
    context: 'selected',
    path: 'event.data.app_id',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    setAppId(app_id)
  }, [app_id]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore setAppId

  return <View {...props} />
}
