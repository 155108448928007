import { useMemo } from 'react'
import { personName } from 'Data/format.js'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(entry => ({
      id: entry.id,
      name: getPaymentAccountName(entry.payment_account),
      amount: entry.due_now,
    }))
  }, [data])
}

function getPaymentAccountName(payment_account) {
  let collection_label =
    payment_account.collection_labels?.[0]?.collection_label_template?.name

  switch (payment_account.account_type) {
    case 'PersonPaymentAccount':
      return collection_label
        ? `${personName(
            payment_account.person_payment_account.payer_person
          )} (${collection_label})`
        : personName(payment_account.person_payment_account.payer_person)
    case 'InsurancePaymentAccount':
      return collection_label
        ? `${payment_account.insurance_payment_accounts[0].insurance_company.carrier_name} (${collection_label})`
        : payment_account.insurance_payment_accounts[0].insurance_company
            .carrier_name
    default:
      return null
  }
}
