import View from './view.js'
import { useFloating, offset, flip, autoUpdate } from '@floating-ui/react-dom'

/**
 * I added a `logic` file in `TysiaSelectOneContent` to implement `useFloating` hook there and
 * keep the popover position without changes on each input selector click.
 * If I implemented on `logic` from parent, I had the issue where the popover position changed on each selector input click
 */
export default function Logic(props) {
  let { refs, x, y, elements } = useFloating({
    open: props.isShowingDatePicker,
    placement: 'bottom-start',
    strategy: 'fixed',
    middleware: [
      flip(),
      offset({
        mainAxis: props.size !== 'small' ? 5 : 12,
      }),
    ],
    whileElementsMounted: autoUpdate, // fixes scrolling issues
  })

  return (
    <View
      {...props}
      parentRef={refs.setReference}
      optionsRef={refs.setFloating}
      optionsLeft={x}
      optionsTop={props.size === 'small' ? y - 10 : y}
      optionsWidth={
        props.optionsWidth ?? elements.reference?.getBoundingClientRect()?.width
      }
    />
  )
}
