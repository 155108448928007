import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { patientName } from 'Data/format.js'

export function useOnClick(props) {
  let person = useDataValue({
    context: 'patient',
    viewPath: props.viewPath,
    path: 'person',
  })
  let patient_id = useDataValue({
    context: 'patient',
    viewPath: props.viewPath,
    path: '_id',
  })
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'tabs/add',
      tab: {
        id: patient_id,
        type: 'Patient',
        name: patientName(person),
        tab: 'profile',
        viewPath: '/App/Account/Content/Patients',
        patient_id,
      },
    })
    setTimeout(() => {
      submit({ type: 'patientOverlay/close' })
    })
  }
}
