import mutationDeletePreset from './mutation-delete-preset.graphql.js'
import mutationUpdatePreset from './mutation-update-preset.graphql.js'
import mutationSavePreset from './mutation-save-preset.graphql.js'
import mutationRenamePreset from './mutation-rename-preset.graphql.js'
import { useMutation } from 'Data/Api'

import { notifyError, useNotifications } from 'Logic/Notifications.js'

export default function useDataOnSubmit(props, data) {
  let [, executionDeletePresetMutation] = useMutation(mutationDeletePreset)
  let [, executionUpdatePresetMutation] = useMutation(mutationUpdatePreset)
  let [, executionRenamePresetMutation] = useMutation(mutationRenamePreset)
  let [, executionSavePresetMutation] = useMutation(mutationSavePreset)
  let [, notify] = useNotifications()

  return async function onSubmit({ value, originalValue, args, change }) {
    try {
      switch (args.type) {
        case 'cancel_preset': {
          change(next => {
            next.selected.filter_preset_name =
              originalValue.selected.filter_preset_name
          })
          break
        }
        case 'delete_preset': {
          let response = await executionDeletePresetMutation({
            id: value.selected.filter_preset_id,
          })

          if (response.error) {
            notify(notifyError('An error has occurred deleting the preset'))
            return
          }

          change(next => {
            next.selected.filter_preset_id = null
            next.selected.filter_preset_name = null
            next.selected.original_filter_values = null
          })
          break
        }
        case 'rename_preset': {
          let response = await executionRenamePresetMutation({
            id: value.selected.filter_preset_id,
            name: value.selected.filter_preset_name,
          })

          if (response.error) {
            notify(notifyError('An error has occurred updating the preset'))
            return
          }

          change(next => {
            next.selected.filter_preset_id =
              response.data.update_reports_filter_presets_by_pk.id
          })
          break
        }
        case 'update_preset': {
          let data = getFilterValues(args.filters, value.selected.filters)

          let response = await executionUpdatePresetMutation({
            id: value.selected.filter_preset_id,
            data,
          })

          if (response.error) {
            notify(notifyError('An error has occurred updating the preset'))
            return
          }

          change(next => {
            next.selected.filter_preset_id =
              response.data.update_reports_filter_presets_by_pk.id
            next.selected.filter_preset_name =
              response.data.update_reports_filter_presets_by_pk.name
            next.selected.original_filter_values = value.selected.filters
          })
          break
        }
        case 'save_preset': {
          let data = getFilterValues(args.filters, value.selected.filters)

          let response = await executionSavePresetMutation({
            report_id: value.selected.report_id,
            name: value.selected.filter_preset_name,
            data,
          })

          if (response.error) {
            notify(notifyError('An error has occurred saving the preset'))
            return
          }

          change(next => {
            next.selected.filter_preset_id =
              response.data.insert_reports_filter_presets_one.id
            next.selected.original_filter_values = next.selected.filters
          })
          break
        }
        default: {
          break
        }
      }
    } catch (error) {
      notify(notifyError('The action could not be performed'))
    }
  }
}

function hasFilter(filters, filter, column_name = null) {
  return filters.some(
    item =>
      item.type === filter &&
      (column_name ? column_name === item.column_name : true)
  )
}

function getFilterValues(report_filters, filters) {
  let data = {}

  if (hasFilter(report_filters, 'DATE_RANGE')) {
    data.start_date = filters.start_date
    data.end_date = filters.end_date
  }
  if (hasFilter(report_filters, 'LOCATION')) {
    data.location_ids = filters.location_ids
  }
  if (hasFilter(report_filters, 'TYPE', 'APPOINTMENT_TYPE')) {
    data.appointment_types_ids = filters.appointment_types
  }
  if (hasFilter(report_filters, 'TYPE', 'PAYER_TYPE')) {
    data.payer_types_ids = filters.payer_types
  }
  if (hasFilter(report_filters, 'TYPE', 'TREATMENT_STATUS')) {
    data.tx_statuses_ids = filters.tx_statuses
  }

  return data
}
