// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Overview/Locations/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_PatientOverlay_Content_Content_Overview_Locations($patient_id: uuid!) {
  vaxiom_tx_cards(
    where: { deleted: { _eq: false }, patient: { _id: { _eq: $patient_id } } }
  ) {
    id
    txs(where: { deleted: { _eq: false } }) {
      id
      organization {
        id
        name
      }
    }
  }
}

`