import React from 'react'
import View from './view.js'
import { normalizePath, useSetFlowTo, useFlowValue } from 'Simple/Flow.js'

export default function Logic(props) {
  let viewPathOptions = normalizePath(props.viewPath, 'Options')
  let flow = useFlowValue(viewPathOptions)
  let setFlowTo = useSetFlowTo(viewPathOptions)

  return <View {...props} onClick={onClick} />

  function onClick(event) {
    // use the full path in setFlowTo not to confuse the linter
    if (flow === 'Content') {
      setFlowTo(normalizePath(props.viewPath, 'Options/No'))
    } else {
      setFlowTo(normalizePath(props.viewPath, 'Options/Content'))
    }
  }
}
