// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/PaymentsRequestPayment/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_EmbeddableCoreMessageListener_PaymentsRequestPayment($id: numeric!) {
  vaxiom_patients_by_pk(id: $id) {
    id
    _id
    tx_cards(
      where: { deleted: { _eq: false }, txs: { deleted: { _eq: false } } }
    ) {
      id
      _id
      name
      txs {
        id
        _id
        name
      }
    }
    person {
      id
      _id
      birth_date
    }
    payment_accounts(
      where: {
        payment_account_id: { _is_null: false }
        insured_id: { _is_null: true }
      }
    ) {
      payment_account {
        id
        person_payment_account {
          id
          _id
        }
      }
      payor {
        id
        _id
        first_name
        greeting
        last_name
        birth_date
      }
    }
  }
}

`