// this is an autogenerated file from App/Account/Search/Content/Profiles/Content/Profile/EditOtherProfessional/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Search_Content_Profiles_Content_Profile_EditOtherProfessional_Content($id: numeric!) {
  vaxiom_professional_relationships_by_pk(id: $id) {
    id
    dtype
    past
    other {
      id
      employee_type {
        id
        name
      }
      person {
        id
        first_name
        middle_name
        last_name
        title
        greeting
        profile_pic_url
        preferred_contact_methods: contact_method_associations(
          where: { preference: { _is_null: false } }
        ) {
          id
          phone {
            id
            number
          }
          email {
            id
            address
          }
        }
      }
      external_office {
        id
        name
        postal_address {
          id
          address_line1
          city
          state
          zip
        }
      }
    }
  }
}

`