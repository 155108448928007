import View from './view.js'

export default function Logic(props) {
  let selected = props.selected?.[0]

  return (
    <View
      {...props}
      line1={selected?.line1 ?? 'Select a date'}
      line2={selected?.line2}
    />
  )
}
