import { useMemo } from 'react'
import { patientName, age, dateShortIn } from 'Data/format.js'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(({ id, human_readable_id, person, primary_location }) => {
      let gender = person.gender[0]
      let maybeAge = person.birth_date
        ? `, ${age(person.birth_date)}, ${dateShortIn(person.birth_date)}`
        : ``
      let name = patientName(person)

      if (name.length > 20) {
        maybeAge = ''
      }

      return {
        id,
        text: `${name}, ${gender} ${maybeAge}`.trim(),
        location: `${
          primary_location?.name ?? ''
        } • #${human_readable_id}`.trim(),
        person,
      }
    })
  }, [data])
}
