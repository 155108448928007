import React, { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

import View from './view.js'

let IDS_NOTIFICATION_MESSAGES = {
  invalid_grant:
    'Patient could not be created in Align. Please reconnect the Invisalign® Doctor Site application from Settings and try again. If the problem persists, contact Support.',
  'Automation to create patient in IDS': {
    failed:
      'Patient could not be created in Align. Please ensure the patient profile in Greyfinch has a recorded address with zip code, gender (female or male), date of birth, and that the first and last names are less than 25 characters',
    completed: 'Patient successfully created in Align',
  },
  'Archive IDS patients': {
    failed: 'Unable to archive patient in IDS',
    completed: 'Patient archived successfully in IDS',
  },
}
let ITERO_NOTIFICATION_MESSAGES = {
  invalid_grant:
    'Scan could not be created in iTero. Please reconnect the iTero application from Settings and try again. If the problem persists, contact Support.',
  'Automation to perform a scan request in iTero': {
    failed: 'Scan could not be created in iTero',
    completed: 'Scan successfully created in iTero',
  },
}

export default function Logic(props) {
  function getMessage({ event_source, event_status, event_name }) {
    switch (event_source) {
      case 'ids': {
        return IDS_NOTIFICATION_MESSAGES[event_name][event_status]
      }
      case 'itero': {
        return ITERO_NOTIFICATION_MESSAGES[event_name][event_status]
      }

      default: {
        return null
      }
    }
  }

  let event_executions = useDataValue({
    context: 'event_executions',
    viewPath: props.viewPath,
  })
  let setRefreshAppsLaunchers = useDataChange({
    context: 'global',
    path: 'refresh_apps_launchers',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()

  useEffect(() => {
    for (let event of event_executions) {
      let event_name = event.action.event.name
      let event_status = event.status
      let event_source = event.action.event.source

      if (event.data.errorMessage?.includes('invalid_grant')) {
        event_name = 'invalid_grant'
      }

      let message = getMessage({ event_name, event_status, event_source })

      notify(
        event_status === 'completed'
          ? notifySuccess(message)
          : notifyError(message),
        {
          hideAfter: 30000,
        }
      )
    }

    if (event_executions.some(event => event.status === 'completed')) {
      setRefreshAppsLaunchers(Date.now())
    }
  }, [event_executions]) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} />
}
