import { useDataValue, useDataChange } from 'Simple/Data'

export function useOnClick(props) {
  let isFloating = useDataValue({
    context: 'tab',
    path: 'sidebar.isFloating',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'tab',
    path: 'sidebar.isFloating',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(!isFloating)
  }
}
