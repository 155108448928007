import React, { useEffect, useImperativeHandle, useState } from 'react'
import View from './view.js'
import useMaskedInput from '@local/use-masked-input'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { conformToMask } from 'text-mask-core'
import useOnClickOutside from '../../useOnClickOutside'
import { useFloating, autoPlacement, offset } from '@floating-ui/react-dom'
import { textToNumber } from 'Data/format.js'

let MASKS = {
  date: {
    mask: [/[0-1]/, /\d/, '/', /[0-3]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/],
    guide: true,
    showMask: true,
  },
  phone: {
    mask: [
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    guide: true,
  },
  fein: {
    mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  twoDigits: {
    mask: [/\d/, /\d/],
    guide: false,
  },
  threeDigits: {
    mask: [/\d/, /\d/, /\d/],
    guide: true,
  },
  fourDigits: {
    mask: [/\d/, /\d/, /\d/, /\d/],
    guide: false,
  },
  fiveDigits: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  sixDigits: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  nineDigits: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  tenDigits: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  currency: {
    mask: createNumberMask({
      allowDecimal: true,
      requireDecimal: true,
      decimalLimit: 2,
      includeThousandsSeparator: true,
      prefix: '$',
    }),
    guide: false,
    pipe: currencyPipe,
  },
  ssn: {
    mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  creditCard: {
    mask: [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    guide: true,
  },
  creditCardExpiration: {
    mask: [/[0-1]/, /\d/, '/', /\d/, /\d/],
    guide: true,
  },
  zip: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
    guide: false,
  },
  signatureRequestCode: {
    mask: [
      /[0-9a-fA-F]/i,
      /[0-9a-fA-F]/i,
      /[0-9a-fA-F]/i,
      /[0-9a-fA-F]/i,
      /[0-9a-fA-F]/i,
      /[0-9a-fA-F]/,
    ],
    guide: true,
  },
}

export default React.forwardRef(function Logic(
  { onSubmit, mask = 'phone', value = '', ...props },
  ref
) {
  let [isShowingDatePicker, setIsShowingDatePicker] = useState(false)

  let { refs, x, y } = useFloating({
    open: isShowingDatePicker,
    strategy: 'fixed',
    middleware: [
      autoPlacement({
        allowedPlacements: [
          'bottom-start',
          'bottom-end',
          'top-start',
          'top-end',
        ],
      }),
      offset({
        mainAxis: 12,
        alignmentAxis: -10,
      }),
    ],
  })

  useOnClickOutside(refs.floating, maybeHideDatePicker)
  if (process.env.REACT_APP_ENV === 'development' && !(mask in MASKS)) {
    throw new Error(
      `${mask} isn't a valid mask. Choose from ${Object.keys(MASKS)}`
    )
  }
  useImperativeHandle(ref, () => ({
    focus: () => {
      refs.reference.current.focus()
    },
  }))

  let onChange = useMaskedInput({
    input: refs.reference,
    ...MASKS[mask],
    onChange: event => {
      props.onChange(event.target.value)
    },
    value: typeof value === 'string' || typeof value === 'number' ? value : '',
  })

  useEffect(() => {
    if (typeof value === 'string' && value === '') return

    if (refs.reference.current) {
      props.onChange(refs.reference.current.value)
    }
  }, [value])

  return (
    <View
      {...props}
      type={props.type === 'date' ? 'text' : props.type}
      isShowingDatePicker={isShowingDatePicker}
      innerRef={refs.setReference}
      datePickerRef={refs.setFloating}
      datePickerY={y}
      datePickerX={x}
      onChange={(event, value) => {
        // value coming from calendar
        if (!event && value) {
          props.onChange(value)
        } else {
          onChange(event)
        }
        maybeHideDatePicker()
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      onSubmit={onSubmit}
      value={value}
    />
  )

  function onFocus(event) {
    if (props.type === 'date') {
      setIsShowingDatePicker(true)
    }
    if (typeof props.onFocus === 'function') {
      props.onFocus(event)
    }
  }

  function onBlur(event) {
    if (
      !(
        event.relatedTarget === refs.floating.current ||
        refs.floating.current?.contains(event.relatedTarget)
      )
    ) {
      maybeHideDatePicker()
    }
    if (typeof props.onBlur === 'function') {
      props.onBlur(event)
    }
  }

  function onKeyUp(event) {
    if (props.onKeyUp && typeof props.onKeyUp === 'function') {
      props.onKeyUp(event)
    } else if (event.key === 'Enter' && typeof onSubmit === 'function') {
      onSubmit()
    } else if (event.key === 'Escape') {
      maybeHideDatePicker()
    }
  }

  function maybeHideDatePicker() {
    if (props.type === 'date') {
      setIsShowingDatePicker(false)
    }
  }
})

function currencyPipe(conformedValue, config) {
  let { previousConformedValue } = config

  let length = conformedValue.length
  let decimalIndex = conformedValue.lastIndexOf('.')

  // This prevents non-numeric characters to be entered into the field
  if (conformedValue === '' && isNaN(Number(config.rawValue))) {
    return false
  }

  // This check handles cases where the user might delete the entire value
  if (conformedValue === '') {
    return (
      conformToMask('0', MASKS['currency'].mask, config).conformedValue + '.00'
    )
  }

  // This check handles cases where a value without a
  // decimal digit is typed into or pasted onto the field
  if (previousConformedValue?.indexOf('.') !== -1 && decimalIndex === -1) {
    let value = textToNumber(conformedValue)
    let previousValue = textToNumber(previousConformedValue)

    // This takes care of situations where the user might delete the decimal point
    if (previousConformedValue && value === previousValue * 100) {
      return false
    } else {
      // This takes care of situations where the user might paste a value without a decimal point
      // Or, when the user types a value without a decimal point (generally a single digit value)
      return {
        value: conformedValue + '.00',
        indexesOfPipedChars: [length, length + 1, length + 2],
      }
    }
  }

  // This handles if values like 1000 are directly passed into the field
  // when initialising it with a value
  if (decimalIndex === -1) {
    return {
      value: conformedValue + '.00',
      indexesOfPipedChars: [length, length + 1, length + 2],
    }
    // This handles if values like 1000. are directly passed into the field
  } else if (decimalIndex === length - 1) {
    return {
      value: conformedValue + '00',
      indexesOfPipedChars: [length, length + 1],
    }
    // This handles if values like 1000.1 are there in the field,
    // and it should be formatted to 1000.10
  } else if (length - decimalIndex === 2) {
    return {
      value: conformedValue + '0',
      indexesOfPipedChars: [length],
    }
    // This handles if values like $.43 are there in the field
  } else if (isNaN(Number(conformedValue[decimalIndex - 1]))) {
    return {
      value:
        conformedValue.slice(0, decimalIndex) +
        '0' +
        conformedValue.slice(decimalIndex),
      indexesOfPipedChars: [decimalIndex],
    }
  }

  return conformedValue
}
