import { useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let slotInterval = useDataValue({
    context: 'slot_interval',
    path: 'id',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'settings',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      key: 'slot_interval',
      value: slotInterval,
    })
  }
}
