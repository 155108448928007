// this is an autogenerated file from DesignSystem/ManualAutomations/Content/ActiveAction/CheckRequirements/Requirements/Requirement/Info/RequiredTreatmentFields/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ManualAutomations_Content_ActiveAction_CheckRequirements_Requirements_Requirement_Info_RequiredTreatmentFields($appointment_id: uuid!) {
  appointments_missing_required_treatment_fields(
    args: { _appointment_id: $appointment_id }
    order_by: { number: asc }
  ) {
    id
    name
  }
}

`