// this is an autogenerated file from App/Account/Content/Calendar/New/Calendar/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Calendar(
  $location_id: uuid!
  $date: date!
  $appt_type_filter: vaxiom_appointment_types_bool_exp!
  $should_filter: Boolean!
  $start_min: timestamp!
  $start_max: timestamp!
) {
  calendar_chairs(
    where: { location_id: { _eq: $location_id }, date: { _eq: $date } }
  ) {
    # for apps/admin/src/Data/ApiCacheExchangeKeys.js
    chair_id
    date
    day_schedule_id
    location_id
    # data
    chair {
      id
      _id
      pos
      full_name
      schedule_notes(
        where: { start_time: { _gte: $start_min, _lte: $start_max } }
      ) {
        id
        _id
        start_time
        duration
        content
        alert_this_day
        is_blocking_time
      }
      appointment_bookings(
        where: {
          local_start_date: { _eq: $date }
          state: { _nin: ["PATIENT_CANCELLED", "OFFICE_CANCELLED"] }
        }
      ) {
        id
        _id
        local_start_time
        duration
        state
        confirmation_status
        provider {
          id
          employment_contract {
            id
            person {
              id
              first_name
              last_name
            }
          }
        }
        assistant {
          id
          employment_contract {
            id
            person {
              id
              first_name
              last_name
            }
          }
        }
        appointment {
          id
          has_notes
          notes {
            id: _id
            note
          }
          patient {
            id
            _id
            person {
              id
              first_name
              greeting
              last_name
              gender
              birth_date
            }
          }
          type {
            id
            display_name
            display_color_id
          }
        }
      }
    }
    provider {
      id
      _id
      title
      first_name
      last_name
    }
    assistant {
      id
      _id
      title
      first_name
      last_name
    }
    office_hours(order_by: { start_min: asc }) {
      day_schedule_id
      start_min
      end_min
    }
    appointment_slots {
      appt_type_id
      appointment_type {
        id
        full_name
      }
    }
    filtered_appt_slots: appointment_slots(
      where: { appointment_type: $appt_type_filter }
    ) @include(if: $should_filter) {
      appt_type_id
      appointment_type {
        appointment_templates(
          where: {
            deleted: { _eq: false }
            organization: { _id: { _eq: $location_id } }
          }
        ) {
          id
          full_name_computed
          color_id_computed
          duration
        }
      }
      day_schedule_id
      start_min
    }
  }
}

`