// this is an autogenerated file from DesignSystem/EditAutomation/TriggerType/TxsStatusUpdate/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_EditAutomation_TriggerType_TxsStatusUpdate{
  vaxiom_tx_statuses(order_by: { name: asc_nulls_last }) {
    id: type
    text: name
  }
}

`