// this is an autogenerated file from DesignSystem/ManualAutomations/Content/ActiveAction/TaskInsert/TaskBasket/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ManualAutomations_Content_ActiveAction_TaskInsert_TaskBasket($organization_id: numeric!) {
  vaxiom_task_baskets(where: { organization_id: { _eq: $organization_id } }) {
    id
    text: title
  }
}

`