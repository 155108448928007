import { DEFAULT_TIMEZONE } from 'Data/constants.js'
import { utcToZonedTime } from 'date-fns-tz'
import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let currentLocation = useDataValue({
    context: 'global',
    path: 'current_location',
    viewPath: props.viewPath,
  })
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })
  let tabs = useDataValue({
    context: 'global',
    path: 'tabs',
    viewPath: props.viewPath,
  })
  // TODO: refactor me
  let appointmentOverlayParams = useMemo(() => {
    return tabs?.find(i => i.id === 'Calendar')?.appointmentOverlayParams
  }, [tabs])

  return useMemo(() => {
    if (!data) return data

    let time_zone_id = currentLocation.time_zone_id || DEFAULT_TIMEZONE
    return {
      ...data,
      selected: {
        ...data.selected,
        parent_company_id,
        location_id: currentLocation._id,
        vaxiom_location_id: currentLocation.id,
        time_zone_id,
        date: utcToZonedTime(new Date().toISOString(), time_zone_id),
      },
      sidebar: {
        ...data.sidebar,
      },
      appointmentOverlay: {
        ...data.appointmentOverlay,
        ...(appointmentOverlayParams
          ? {
              open: true,
              patient_id: appointmentOverlayParams.patient_id,
              appointment_id: appointmentOverlayParams.appointment_id,
            }
          : {}),
      },
    }
  }, [
    data,
    currentLocation._id,
    currentLocation.id,
    currentLocation.time_zone_id,
    parent_company_id,
    appointmentOverlayParams,
  ])
}
