import { useDataValue } from 'Simple/Data.js'

export default function useGetDefaultTab(props, data) {
  let current_location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  return function ensureDefaultTab(maybeTab, id) {
    if (
      maybeTab?.selected &&
      maybeTab.selected.locations.includes(current_location_id)
    )
      return maybeTab

    return {
      ...data,
      id,
      type: id,
      name: 'Patients',
      viewPath: props.viewPath,
      selected: {
        ...data.selected,
        locations: [current_location_id],
      },
    }
  }
}
