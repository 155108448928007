import { useMemo } from 'react'
import { patientName } from 'Data/format.js'
import { v4 as uuid } from 'uuid'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let payment_accounts = data[0].payment_accounts.map(i => {
      if (typeof i.insured_id === 'number') {
        return {
          id: `${i.payment_account_id}`,
          // prettier-ignore
          text: `${i.insurance_company.carrier_name} (Group/Plan: ${i.insurance_plan.number}) - ${patientName(i.payor)}`,
          data: {
            payer_person: i.payor,
            payment_account_id: i.payment_account_id,
            insured_id: i.insured_id,
            is_new: false,
          },
        }
      }

      let is_new = typeof i.payment_account_id !== 'number'

      // uuid, in case payment account does not exists, new one will be created
      // e.g. responsible people, patient itself, they might not have payment accounts if they are new
      return {
        id: `${i.payment_account_id || uuid()}`,
        text: `${patientName(i.payor)}${is_new ? ' [NEW]' : ''}`,
        data: {
          payer_person: i.payor,
          payment_account_id: i.payment_account_id || null,
          is_new,
        },
      }
    })

    payment_accounts.sort((a, b) => a.text.localeCompare(b.text))

    return payment_accounts
  }, [data])
}
