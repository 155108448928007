import View from './view.js'
import { useDataChange, useDataValue, DataProvider } from 'Simple/Data'
import { useMemo } from 'react'
import { dateISOToDateAndTime } from 'Data/format'
import { join } from 'Data/aggregate'
import TysiaCheckbox from 'DesignSystem/TysiaCheckbox/index.js'
import TysiaCheckboxInput from 'DesignSystem/TysiaCheckbox/TysiaCheckboxInput/index.js'
import ActionsAction from './ActionsAction/index.js'
import NameAction from './NameAction/index.js'

export default function Logic(props) {
  let notes = useDataValue({
    context: 'note_templates',
    viewPath: props.viewPath,
  })
  let selected = useDataValue({
    context: 'note_templates_tab',
    path: 'note_templates',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'note_templates_tab',
    viewPath: props.viewPath,
  })

  let tableData = useMemo(
    () => ({
      columns: [
        {
          Header: '',
          accessor: 'id',
          Cell: row => (
            <TysiaCheckbox
              onChange={value => {
                change(next => {
                  if (value) {
                    next.note_templates.push(row.value)
                  } else {
                    next.note_templates = next.note_templates.filter(
                      item => item !== row.value
                    )
                  }
                })
              }}
              value={selected.includes(row.value)}
              viewPath={props.viewPath}
            >
              {childProps => <TysiaCheckboxInput {...childProps} />}
            </TysiaCheckbox>
          ),
        },
        {
          Header: 'Name',
          accessor: 'name',
          Cell: ({ row }) => {
            return (
              <DataProvider
                context="note_template"
                value={row.original}
                viewPath={props.viewPath}
              >
                <NameAction
                  viewPath={`${props.viewPath}/NameAction(${row.id})`}
                />
              </DataProvider>
            )
          },
        },
        {
          Header: 'Used in',
          accessor: 'tags',
          Cell: ({ value }) => join(value),
        },
        {
          Header: 'Last edited by',
          accessor: 'updated_by',
          Cell: ({ value }) => value ?? '-',
        },
        {
          Header: 'Last edit',
          accessor: 'updated_at',
          Cell: ({ value }) => dateISOToDateAndTime(value),
        },
        {
          Header: '',
          accessor: 'organization_id',
          Cell: ({ row }) => {
            return (
              <DataProvider
                context="note_template"
                value={row.original}
                viewPath={props.viewPath}
              >
                <ActionsAction
                  viewPath={`${props.viewPath}/ActionsAction(${row.id})`}
                />
              </DataProvider>
            )
          },
        },
      ],
      data: notes ?? [],
    }),
    [notes, selected]
  )

  return <View viewPath={props.viewPath} tableData={tableData} />
}
