import React, { useRef } from 'react'
import View from './view.js'
import useOnClickOutside from 'use-onclickoutside'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export default function Logic(props) {
  let buttonRef = useRef()
  let contentRef = useRef()

  let setFlowTo = useSetFlowTo(props.viewPath)

  useOnClickOutside(contentRef, event => {
    if (
      event.target === buttonRef.current ||
      buttonRef.current?.contains(event.target)
    ) {
      return
    }

    setFlowTo(normalizePath(props.viewPath, 'No'))
  })

  return (
    <View {...props} copyButtonRef={buttonRef} copyContentRef={contentRef} />
  )
}
