import { useClient, useMutation } from 'Data/Api.js'
import setup from './setup.js'
import queryProviderLocationAccount from './query-provider-location-account.graphql.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import {
  isBankAccountMetadataValid,
  isCreditCardMetadataValid,
} from './helpers.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionInitialize = useDataOnActionInitialize(props, data)
  let onActionRender = useDataOnActionRender(props, data)
  let onActionTokenize = useDataOnActionTokenize(props, data)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args?.type) {
      case 'initialize': {
        return await onActionInitialize({ value, originalValue, args, change })
      }
      case 'render': {
        return await onActionRender({ value, originalValue, args, change })
      }
      case 'tokenize': {
        return await onActionTokenize({ value, originalValue, args, change })
      }
      default: {
        throw new Error(`Unkwnown payment action ${args.type}`)
      }
    }
  }
}

function useDataOnActionInitialize(props, data) {
  let client = useClient()

  return async function onAction({ value, originalValue, args, change }) {
    if (!args.locationId) return

    try {
      if (value.state !== 'loading') {
        change(next => {
          next.state = 'loading'
          next.containerId = `${next.containerId.split('--')[0]}--${Date.now()}`
          next.render = null
          next.submit = null
          next.provider = null
          next.provider_location_account_id = null
          next.person_id = null
          next.reusable = false
          next.valid = false
        })
      }
      let response = await client
        .query(queryProviderLocationAccount, { location_id: args.locationId })
        .toPromise()
      if (response.error) {
        let error = new Error('PaymentProvider/query-provider-location-account')
        error.meta = response.error
        throw error
      }

      let providerLocationAccount =
        response.data.payments_provider_location_accounts_default_for_location
      if (!providerLocationAccount) {
        let error = new Error(
          'PaymentProvider/missing-provider-location-account'
        )
        error.meta = response.error
        throw error
      } else if (providerLocationAccount.status !== 'active') {
        let error = new Error('PaymentProvider/payment-provider-not-verified')

        change(next => {
          next.provider_status = providerLocationAccount.status
        })
        throw error
      }

      let render = await setup({
        provider: providerLocationAccount.provider,
      })

      change(next => {
        next.state = 'ready'
        next.render = render
        next.provider = providerLocationAccount.provider
        next.provider_account_id = providerLocationAccount.account_id
        next.provider_location_account_id = providerLocationAccount.id
        next.provider_status = providerLocationAccount.status
        next.person_id = args.person._id
        next.person = args.person
        next.valid = false
        next.reusable =
          typeof args.reusable === 'boolean' ? args.reusable : false
        next.method = args.method
        next.token_id = null
        next.add_new = true
      })
    } catch (error) {
      console.error({
        type: 'onActionInitialize/setup',
        message: error.message,
        stack: error.stack,
        meta: error.meta,
        location_id: args.locationId,
      })
      change(next => {
        next.state = 'error'
        next.render = null
        next.submit = null
        next.provider_location_account_id = null
        next.person_id = null
        next.reusable = false
        next.valid = false
      })
    }
  }
}

function useDataOnActionRender(props, data) {
  return async function onAction({ value, originalValue, args, change }) {
    if (typeof value.render !== 'function') return

    try {
      let submit = value.render({
        accountId: value.provider_account_id,
        containerId: value.containerId,
        method: value.method,
        person: value.person,
        onValid: valid => change(valid, 'valid'),
      })
      change(next => {
        next.submit = submit
        next.render = null
      })
    } catch (error) {
      console.error({
        type: 'onActionInitialize/setup',
        message: error.message,
        stack: error.stack,
        meta: error.meta,
        location_id: args.locationId,
      })
      change(next => {
        next.state = 'error'
        next.render = null
        next.submit = null
        next.provider_location_account_id = null
        next.person_id = null
        next.reusable = false
        next.valid = false
        next.method = null
      })
    }
  }
}

function useDataOnActionTokenize(props, data) {
  let [, notify] = useNotifications()

  return async function onAction({ value, originalValue, args, change }) {
    if (!value.add_new && value.token_id) {
      return {
        type: 'token',
        token_id: value.token_id,
      }
    }

    try {
      let first_name = null
      let last_name = null
      let zip = null

      /**
       * For bluefin, we pass some extra piece of information - first name, last name, and zip code (if it is a card)
       * In data.json, the card values come from value.credit_card object, and for ach, the values come from value.bank_account object
       */
      if (value.provider === 'bluefin') {
        if (value.method === 'card') {
          if (!isCreditCardMetadataValid(value.credit_card)) {
            notify(
              notifyError(
                'The credit card details provided are missing or invalid.'
              )
            )
            return true
          }
          zip = value.credit_card.zip_code
          first_name = value.credit_card.holder_first_name
          last_name = value.credit_card.holder_last_name
        } else {
          if (!isBankAccountMetadataValid(value.bank_account)) {
            notify(
              notifyError(
                'The bank account details provided are missing or invalid.'
              )
            )
            return true
          }
          first_name = value.bank_account.holder_first_name
          last_name = value.bank_account.holder_last_name
        }
      }
      let response = await value.submit()

      if (zip !== null) {
        response.zip = zip
      }
      if (first_name !== null && last_name !== null) {
        response.first_name = first_name
        response.last_name = last_name
      }

      let token_claim_request = {
        response: response,
        provider: value.provider,
        provider_location_account_id: value.provider_location_account_id,
        type: value.method,
        person_id: value.person_id,
        reusable: value.reusable,
      }

      change(next => {
        next.add_new = false
        next.credit_card = {
          holder_first_name: '',
          holder_last_name: '',
          zip_code: '',
        }
        next.bank_account = {
          holder_first_name: '',
          holder_last_name: '',
        }
      })

      return {
        type: 'token_claim',
        token_claim_request,
      }
    } catch (error) {
      console.error({ type: 'PaymentProvider/tokenize', error })

      let message
      if (value.provider === 'bluefin') {
        message = JSON.parse(error.graphQLErrors[0].message).response
          .error_message
      } else if ('invalidInputs' in error) {
        // This handles invalid input case for bluefin
        message = error.invalidInputs.map(getBluefinErrorMessage).join('. ')
      } else if ('resultText' in error) {
        // This handles the promise rejection from functionCallbackSuccess for payabli
        message = error.resultText
      } else {
        message = 'Payment failed to process, please try another payment method'
      }

      notify(notifyError(message))
    }
  }
}

/**
 *
 * @param {{ field: 'number' | 'cvv' | 'expy' | 'bank account' | 'routing number', code: 1000 | 1001 | 1002 | 1003 | 1004 | 1005 | 1006, message: string}} invalidInput
 */
function getBluefinErrorMessage({ field, code, message }) {
  switch (code) {
    case 1000:
      // blank field
      return `${fieldMap[field].text} is required`
    case 1001:
      // input too short
      return `${fieldMap[field].text} must be ${fieldMap[field].maxLength} digits`
    case 1002:
      // input too long
      return `${fieldMap[field].text} must be ${fieldMap[field].maxLength} digits`
    case 1003:
      // input not numeric
      return `${fieldMap[field].text} should be a number`
    case 1004:
      // invalid card number
      return `Card number is invalid. Please try again.`
    case 1005:
      // invalid expiry date
      return `Expiry date is invalid. Please try again.`
    case 1006:
      // invalid routing number
      return `Routing number is invalid. Please try again.`
    default:
      // Unknown error code
      return `${fieldMap[field].text}: ${message}`
  }
}

let fieldMap = {
  number: { text: 'Card number', maxLength: 12 },
  cvv: { text: 'CVV', maxLength: 3 },
  expy: { text: 'Expiry date', maxLength: 4 },
  'bank account': { text: 'Bank Account' },
  'routing number': { text: 'Routing Number', maxLength: 9 },
}
