import React, { useEffect, useMemo, useRef } from 'react'
import { useDataChange } from 'Simple/Data.js'
import { Editor as TinyMceEditor } from '@tinymce/tinymce-react'
import './style.css'

let TOOLBAR_ARRANGEMENTS = {
  default:
    'insertfile undo redo | fontselect styleselect | bold italic | fontsizeselect forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
  note_template: 'bold italic underline bullist numlist forecolor backcolor',
}

function generateMenuItem(item, editor) {
  return {
    type: item.type,
    text: item.text,
    onAction: () => {
      // Insert content at the location of the cursor.
      // https://www.tiny.cloud/docs/api/tinymce/tinymce.editor/#insertcontent
      editor.insertContent(item.value)
    },
  }
}

export default function EditableTextArea(props) {
  let setEditor = useDataChange({
    context: 'editor',
    viewPath: props.viewPath,
  })

  let initConfig = useMemo(() => {
    let initConfig = {
      // core wrongly outputs <span><li>... using spain instead of ul or ol, this lets Tiny allow it
      // https://www.tiny.cloud/docs/configure/content-filtering/#valid_children
      valid_children: '+body[style],+span[li],+div[li]',
      plugins: [
        'advlist',
        'autolink',
        'lists',
        'link',
        'charmap',
        'preview',
        'searchreplace',
        'visualblocks',
        'code',
        'fullscreen',
        'insertdatetime',
        'table',
      ],
      toolbar:
        typeof props.toolbar === 'boolean'
          ? props.toolbar
          : TOOLBAR_ARRANGEMENTS[props.toolbar] || TOOLBAR_ARRANGEMENTS.default,
      menubar:
        typeof props.menubar === 'boolean'
          ? props.menubar
          : 'edit insert view format table tools',
      contextmenu: false,
      content_style:
        "@import url('https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic')",
      font_formats:
        'Arial=arial,helvetica,sans-serif; Calibri=calibri; Georgia=georgia,palatino; Helvetica=helvetica; Times New Roman=times new roman,times',
      width: '100%',
      height: '100%',
      init_instance_callback: editor => {
        // After editor is initialized set the true content
        editor.resetContent(props.value)
      },
      statusbar: false,
      skin_url: `${window.location.origin}/tinymce-skin`,
      selector: props.selector ? `.${props.selector}` : null,
      inline: props.inline || false,
      fixed_toolbar_container: props.inlineToolbarContainer
        ? `#${props.inlineToolbarContainer}`
        : null,
      toolbar_persist: props.toolbarPersist || false,
      browser_spellcheck: true,
      text_patterns: [],
    }

    if (props.tokens) {
      let { name, label, tooltip, items: menuItems } = props.tokens
      initConfig.toolbar = initConfig.toolbar.concat(` | ${name} `)
      initConfig.setup = editor => {
        // Register a custom toolbar menu button to insert tokens
        // https://www.tiny.cloud/docs/ui-components/typesoftoolbarbuttons/#menubutton
        editor.ui.registry.addMenuButton(name, {
          text: label,
          tooltip,
          fetch: callback => {
            let items = menuItems.map(item => {
              if (item.type === 'nestedmenuitem') {
                return {
                  type: item.type,
                  text: item.text,
                  getSubmenuItems: () =>
                    item?.submenu_items?.map(submenuItem =>
                      generateMenuItem(submenuItem, editor)
                    ) ?? [],
                }
              } else {
                return generateMenuItem(item, editor)
              }
            })
            callback(items)
          },
        })
      }
    }

    if (props.autocomplete) {
      initConfig.setup = editor => {
        editor.ui.registry.addAutocompleter('tinymce-autocompleter', {
          trigger: '/',
          minChars: 0,
          columns: 1,
          onAction: (autocompleteApi, rng, value) => {
            editor.selection.setRng(rng)
            props.onAutocompleteAction(value)
            autocompleteApi.hide()
          },
          fetch: props.onAutocompleteFetch,
        })
      }
    }

    return initConfig
  }, [
    props.value,
    props.tokens,
    props.menubar,
    props.toolbar,
    props.autocomplete,
    props.selector,
    props.inline,
    props.inlineToolbarContainer,
    props.toolbarPersist,
  ])

  let onChangeRef = useRef(props.onChange)
  useEffect(() => {
    onChangeRef.current = props.onChange
  }, [props.onChange])

  return (
    <TinyMceEditor
      tinymceScriptSrc={`${window.location.origin}/tinymce/tinymce.min.js`}
      apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
      onInit={handleOnInit}
      onDirty={() =>
        setEditor(next => {
          next.isDirty = true
        })
      }
      onEditorChange={value => {
        if (typeof onChangeRef.current === 'function') {
          onChangeRef.current(value)
        }
      }}
      initialValue="" /* Initialize Editor Empty to prevent the base64 image issue */
      init={initConfig}
    />
  )

  function handleOnInit(evt, editor) {
    setEditor(next => {
      next.api = editor
      next.isInitialized = true
    })
  }
}
