import React, { useMemo } from 'react'
import { useTable, useExpanded } from 'react-table'

function TreatmentTable(props) {
  let data = useMemo(() => props.values, [])

  let columns = useMemo(
    () => [
      {
        Header: 'Treatment Name',
        accessor: 'name',

        Cell: ({ row }) => {
          return row.canExpand ? (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? '👇' : '👉'}{' '}
              {row.values.name || row.original.type}
            </span>
          ) : (
            row.values.name
          )
        },
      },
      {
        Header: 'Treatment Length',
        accessor: 'length',
      },
      {
        Header: 'Treatment Fee',
        accessor: 'fee',
      },
    ],
    []
  )

  let { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        getSubRows: row => row.plans || [],
        initialState: {
          expanded: true,
        },
        state: {
          expanded: true,
        },
      },
      useExpanded
    )

  let useRowClick = typeof props.onRowClick === 'function'

  return (
    <table
      {...getTableProps()}
      style={{
        fontFamily: props.fontFamily,
        fontWeight: props.fontWeight,
        fontSize: props.fontSize,
        color: props.color,
        borderSpacing: 0,
        marginTop: props.marginTop,
        marginLeft: props.marginLeft,
        marginRight: props.marginRight,
        marginBottom: props.marginBottom,
        borderRadius: props.borderRadius,
        borderWidth: props.borderWidth,
        borderStyle: props.borderStyle,
        borderColor: props.borderColor,
        overflow: props.overflow,
      }}
    >
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps()}
                style={{
                  cursor: props.cursorHeader,
                  backgroundColor: props.backgroundColorHeader,
                  borderBottomWidth: props.borderBottomWidthHeader,
                  borderBottomStyle: props.borderBottomStyleHeader,
                  borderBottomColor: column.columns
                    ? 'transparent'
                    : props.borderBottomColorHeader,
                  borderLeftWidth: props.borderLeftWidthHeader,
                  borderLeftStyle: props.borderLeftStyleHeader,
                  borderLeftColor: props.borderLeftColorHeader,
                  height: props.heightHeader,
                  fontWeight: props.fontWeightHeader,
                  paddingLeft: props.paddingLeftHeader,
                  paddingRight: props.paddingRightHeader,
                }}
              >
                {column.render('Header')}
                <span>
                  {props.useCustomOrder
                    ? column.sortedDesc === undefined
                      ? ''
                      : column.sortedDesc === true
                      ? ' ↓'
                      : ' ↑'
                    : column.isSorted
                    ? column.isSortedDesc
                      ? ' ↓'
                      : ' ↑'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          let isLast = i === rows.length - 1
          prepareRow(row)
          return (
            <React.Fragment key={i}>
              <tr
                {...row.getRowProps()}
                onClick={
                  !row.canExpand && useRowClick
                    ? () => props.onRowClick(row)
                    : null
                }
                style={{
                  height: props.heightRow,
                  color: props.colorRow,
                  cursor: useRowClick ? 'pointer' : 'default',
                  backgroundColor:
                    typeof props.isSelected === 'function' &&
                    props.isSelected(row)
                      ? props.backgroundColorRowSelected
                      : !!props.onGetBackgroundRowColor &&
                        typeof props.onGetBackgroundRowColor === 'function'
                      ? props.onGetBackgroundRowColor(row)
                      : props.backgroundColorRow,
                }}
              >
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      colSpan={row.canExpand ? 3 : 1}
                      style={{
                        borderBottomWidth: isLast
                          ? 0
                          : props.borderBottomWidthCell,
                        borderBottomStyle: isLast
                          ? 'none'
                          : props.borderBottomStyleCell,
                        borderBottomColor: isLast
                          ? 'transparent'
                          : props.borderBottomColorCell,
                        paddingLeft: props.paddingLeftCell,
                        paddingRight: props.paddingRightCell,

                        borderRightWidth: props.rightBorder
                          ? props.borderBottomWidthCell
                          : 0,
                        borderRightStyle: props.rightBorder
                          ? props.borderBottomStyleCell
                          : 'none',
                        borderRightColor: props.rightBorder
                          ? props.borderBottomColorCell
                          : 'transparent',
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            </React.Fragment>
          )
        })}
      </tbody>
    </table>
  )
}

export default function Logic(props) {
  return <TreatmentTable {...props} />
}
