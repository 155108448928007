// this is an autogenerated file from DesignSystem/EditAutomation/EventActions/Content/EventAction/Content/Http/Content/AppConnection/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_EditAutomation_EventActions_Content_EventAction_Content_Http_Content_AppConnection($parent_company_id: uuid!) {
  apps_connections(
    where: { parent_company_id: { _eq: $parent_company_id } }
    order_by: { app: { name: asc }, name: asc }
  ) {
    id
    name
    app {
      id
      name
    }
  }
}

`