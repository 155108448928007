import { useDataChange } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props) {
  let change = useDataChange({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
  })

  return value => {
    if (!value) return

    change(next => {
      next.duration = value.duration
      next.appointment_id = value.id
      if (value.note) {
        next.notes.enabled = true
        next.notes.value = value.note
      }
    })
  }
}
