// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Overview/Comms/Actions/Content/ContactMethods/queryDiscussion.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_PatientOverlay_Content_Content_Overview_Comms_Actions_Content_ContactMethods($resources: jsonb) {
  comms_threads(
    where: { resources: { _contains: $resources }, type: { _eq: internal } }
  ) {
    id
  }
}

`