// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Overview/Comms/Actions/Content/ContactMethods/mutationSms.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_PatientOverlay_Content_Content_Overview_Comms_Actions_Content_ContactMethods($number: PhoneNumber!) {
  thread: comms_threads_sms_create(number: $number) {
    id
  }
}

`