// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Overview/Comms/Actions/Content/ContactMethods/mutationInApp.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_PatientOverlay_Content_Content_Overview_Comms_Actions_Content_ContactMethods($person_id: numeric!) {
  thread: comms_threads_in_app_create(person_id: $person_id) {
    id
  }
}

`