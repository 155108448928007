import { useDataValue } from 'Simple/Data.js'
import mutation from './mutation.graphql.js'
import { useMutation } from 'Data/Api.js'

export function useOnClick(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })
  let payment_account_id = useDataValue({
    context: 'patient',
    path: 'person.person_payment_account.id',
    viewPath: props.viewPath,
  })
  let parent_company_id = useDataValue({
    context: 'patient',
    path: 'organization.id',
    viewPath: props.viewPath,
  })
  let [, executeMutation] = useMutation(mutation)

  return async function onClick() {
    await executeMutation({
      patient_id,
      amount: Math.ceil(Math.random() * 5_000),
      payment_account_id,
      parent_company_id,
    })
  }
}
