import { useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let data = useDataValue({
    viewPath: props.viewPath,
    context: 'launcher',
  })

  return function onClick() {
    switch (data.resolver.type) {
      case 'url': {
        window.open(data.resolver.data)
        break
      }

      case 'http': {
        alert(`TODO: implement http resolver`)
        break
      }

      default: {
        throw new Error(`Unknown resolver type "${data.resolver.type}"`)
      }
    }
  }
}
