import { useDataChange } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    if (!data) return
    change(next => {
      next.selected.topbar_appointment_types =
        value.vaxiom_selected_appointment_templates.map(
          temp => temp.appointment_template.id
        )
    })
  }
}
