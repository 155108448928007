import { useMemo } from 'react'

export default function useDataConfiguration(props) {
  let created_at = useMemo(() => new Date(), [])
  return {
    variables: {
      created_at,
    },
  }
}
