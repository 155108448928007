import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api'
import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props) {
  let [, executeMutation] = useMutation(mutation)

  let vaxiom_user_id = useDataValue({
    context: 'global',
    viewPath: props.viewPath,
    path: 'feature_flags.vaxiom_user_id',
  })

  let vaxiom_location_id = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected.vaxiom_location_id',
  })

  return async function onSubmit({ value, args }) {
    let selected_appointment_templates = args.map(
      arg =>
        value.vaxiom_appointment_templates.find(v => v.id === arg).vaxiom_id
    )

    await executeMutation({
      location_id: vaxiom_location_id,
      objects: selected_appointment_templates.map(appointment_template_id => ({
        appointment_template_id,
        user_id: vaxiom_user_id,
        location_id: vaxiom_location_id,
      })),
    })
  }
}
