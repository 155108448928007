import { useMemo } from 'react'
import { startOfMonth, endOfMonth, isAfter, addDays } from 'date-fns'
import { format } from 'Data/date.js'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let date = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected.date',
  })

  let { first_day_of_month, last_day_of_month } = useMemo(() => {
    if (!date) return
    return {
      first_day_of_month: startOfMonth(date),
      last_day_of_month: endOfMonth(date),
    }
  }, [date])

  return useMemo(() => {
    if (!data) return data

    let contextData = []

    /** @type {Date} */
    let currentDate = first_day_of_month
    while (!isAfter(currentDate, last_day_of_month)) {
      let dateFormat = format(currentDate, 'yyyy-MM-dd')
      let isWeekend = currentDate.getDay() === 6 || currentDate.getDay() === 0
      if (isWeekend) {
        contextData.push({
          date: dateFormat,
          ratio: -1,
          hasReservation: false,
          occupancyColor: getOccupancyColor(-1),
          templateColor: getOfficeDayTemplateColor(undefined, true),
          isWeekend,
        })
        currentDate = addDays(currentDate, 1)
        continue
      }

      let chairs = data?.chairs.map(chair => ({
        ...chair,
        appointment_bookings: chair.appointment_bookings.filter(
          appointment_booking =>
            appointment_booking.local_start_date === dateFormat
        ),
        resource: {
          ...chair.resource,
          work_schedule_days: chair.resource.work_schedule_days.filter(
            work_schedule_day => work_schedule_day.ws_date === dateFormat
          ),
        },
      }))
      let offices = data?.offices
      let office_day = data?.office_days.find(
        office_day => dateFormat === office_day.o_date
      )

      let providers_and_assistants = data?.allocations
        .filter(allocation => dateFormat === allocation.ca_date)
        ?.map(allocation => ({
          id: allocation.resource.id,
          person:
            allocation.resource.employee_resources?.[0]?.employment_contract
              ?.person,
          role: allocation.resource.resource_type.is_provider
            ? 'Provider'
            : allocation.resource.resource_type.is_assistant
            ? 'Assistant'
            : '',
        }))
        ?.reduce((acc, current) => {
          if (!acc.some(entry => entry.id === current.id)) {
            acc.push(current)
          }
          return acc
        }, [])

      let ratio = calculateOccupancy(chairs, offices)

      contextData.push({
        date: dateFormat,
        ratio,
        hasReservation: ratio !== -1,
        occupancyColor: getOccupancyColor(ratio),
        templateColor: getOfficeDayTemplateColor(office_day, false),
        templateName: office_day?.template?.name ?? '',
        providers_and_assistants,
        isWeekend,
      })
      currentDate = addDays(currentDate, 1)
    }

    return contextData
  }, [data, first_day_of_month, last_day_of_month])
}

function calculateOccupancy(chairSlots, offices) {
  /** @type number */
  let availableMinutes = 0

  /** @type number */
  let occupiedMinutes = 0

  chairSlots.forEach(chair => {
    chair.resource.work_schedule_days.forEach(work_schedule_day => {
      work_schedule_day.day_schedule?.day_schedule_office_hours.forEach(
        office_hour => {
          availableMinutes += office_hour.end_min - office_hour.start_min
        }
      )
    })

    occupiedMinutes += chair.appointment_bookings
      .map(appointment_booking => appointment_booking.duration)
      .reduce((a, b) => a + b, 0)
  })

  offices.forEach(office => {
    office.resource.day_schedules.forEach(ds => {
      ds.day_schedule_office_hours.forEach(office_hour => {
        availableMinutes += office_hour.end_min - office_hour.start_min
      })
    })
  })

  let ratio = -1
  if (availableMinutes > 0)
    ratio = parseFloat((occupiedMinutes / availableMinutes).toFixed(2))
  return ratio
}

let ColorMap = {
  GREEN: '#D5FFD6',
  ORANGE: '#FBE7CA',
  PINK: '#FFD9E6',
  BLUE: '#CBECFE',
  YELLOW: '#EEE8B2',
  GRAY: '#CBD2D9',
  WHITE: '#FFFFFF',
  VIOLET: '#E3D5FA',
  OUT_OF_OFFICE: '#F5F7FA',
  CHAIR_CLOSED: '#9AA5B1', // Dark Gray
  UNDEFINED: '#F5F7FA',
}

function getOccupancyColor(ratio) {
  if (ratio === -1) return ''

  if (ratio <= 0.3) return '#3F9142'
  else if (ratio >= 0.67) return '#BA2525'
  else return '#E9B949'
}

function getOfficeDayTemplateColor(office_day, isWeekend) {
  if (isWeekend) return '#9AA5B1'
  // Template is not defined
  if (!office_day?.template?.color) return ColorMap['UNDEFINED']

  let color = office_day.template.color
  return ColorMap[color in ColorMap ? color : 'UNDEFINED']
}
