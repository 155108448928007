import { useDataValue } from 'Simple/Data'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutation_send_hub_invite from './sendHubInviteMutation.graphql.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeSendHubInviteMutation] = useMutation(mutation_send_hub_invite)

  let email = useDataValue({
    viewPath: props.viewPath,
    context: 'email',
  })

  // send hub invite requires numeric location id
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.id',
  })

  return async () => {
    // hide dropdown
    setFlowTo(normalizePath(props.viewPath, '../../No'))

    let mutationSendHubInviteResponse = await executeSendHubInviteMutation({
      email: email.email.address,
      location_id,
    })

    if (
      mutationSendHubInviteResponse.error ||
      !mutationSendHubInviteResponse.data.auth_patient_invite.ok
    ) {
      notify(
        notifyError(
          'Make sure that the patient is at least 18 years old or that they have a responsible person.'
        )
      )
      return
    }

    notify(notifySuccess('Hub Invite Sent!'))
  }
}
