import React, { useMemo, useRef, useState } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import useIsHovered from 'Simple/hooks/useIsHovered.js'
import useIsFocused from 'Simple/hooks/useIsFocused.js'

import View from './view.js'

// escape regex - https://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript
function escapeRegex(string) {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
}

/**
 * I could not implement `useFloating` here (for me it is the place to implement it) because I had an issue
 * when you clicked multiple times over the selector. The options popover changed the position to another different
 * each time that I clicked on the input.
 * For this reason I added a `logic` file in `TysiaSelectOneContent` to implement `useFloating` hook there and
 * keep the popover position without changes on each input selector click
 * */
export default function Logic(props) {
  let from = useMemo(() => {
    if (!props.from) return []

    return props.from.map(item => ({
      ...item,
      id: props.id ? item[props.id] : item.id ?? item._id,
    }))
  }, [props.from, props.id])

  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: props.disabled,
    isSelected: false,
  })
  let [focused, onFocusBind, onBlurBind] = useIsFocused({
    onFocus: props.onFocus,
    onBlur: props.onBlur,
  })
  let isMultiChoice = Array.isArray(props.value)
  let customOptionsAvailable = props.customOptionsAvailable || false
  let allowEmptyChoice = props.allowEmptyChoice ?? true
  let [isShowingOptions, setIsShowingOptions] = useState(false)

  let [valueFilter, setValueFilter] = useState(props.valueFilter || '')
  let [fromCustom, setFromCustom] = useState(getFromCustom)
  let [optionValue, setOptionValue] = useState('')
  // I kept this reference because we are using in other children different of Popover too.
  let optionsRef = useRef(null)
  let inputRef = useRef()
  let parentRef = useRef()
  useOnClickOutside(optionsRef, event => {
    if (
      event.target === inputRef.current ||
      inputRef.current?.contains(event.target)
    ) {
      return
    }

    setIsShowingOptions(false)
  })

  let fromValues = useFilteredValues(from, valueFilter)
  let fromCustomValues = useFilteredValues(fromCustom, valueFilter)

  // TODO: add a prop to turn custom on or not
  let isShowingAddFilterAsCustomOption =
    valueFilter !== '' &&
    fromCustom.every(item => item.text !== valueFilter) &&
    from.every(item => item.text !== valueFilter)

  let isSelectedAll = isMultiChoice ? from.length === props.value.length : false
  let isEmpty = from.length === 0

  return (
    // Added parentRef the click outside hook to avoid close the options popover when you click on the filter input.
    <View
      {...props}
      focused={focused}
      hovered={hovered}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
      selected={[...fromCustom, ...from].filter(isSelected)}
      from={fromValues}
      fromCustom={fromCustomValues}
      isShowingOptions={isShowingOptions}
      isMultiChoice={isMultiChoice}
      allowEmptyChoice={allowEmptyChoice}
      showSelectAll={isMultiChoice && !valueFilter?.length}
      inputRef={inputRef}
      optionsRef={optionsRef}
      parentRef={parentRef}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyUp={onKeyUp}
      onClick={onClick}
      onClickAddFilterAsCustomOption={onClickAddFilterAsCustomOption}
      onClickToggleAll={onClickToggleAll}
      placeholder={getPlaceholder()}
      isShowingAddFilterAsCustomOption={isShowingAddFilterAsCustomOption}
      isEmpty={isEmpty}
      isSelected={isSelected}
      isSelectedAll={isSelectedAll}
      onChangeFilter={onChangeFilter}
      selectedPlaceholder={props.selectedPlaceholder}
      valueFilter={valueFilter}
      optionValue={optionValue}
    />
  )

  function onChangeFilter(_, value) {
    setValueFilter(value)
    setIsShowingOptions(true)

    if (typeof props.onChangeFilter === 'function') {
      props.onChangeFilter(value)
    }
  }

  function onClick(id) {
    if (typeof props.onChange !== 'function') return

    let isAlreadySelected = isSelected({ id })
    let value
    if (isMultiChoice) {
      if (isAlreadySelected) {
        value =
          allowEmptyChoice || (!allowEmptyChoice && props.value.length > 1)
            ? props.value.filter(el => el !== id)
            : props.value
      } else value = [...props.value, id]
    } else {
      value = isAlreadySelected
        ? props.allowEmptyChoice !== false
          ? null
          : id
        : id
    }

    props.onChange(value)

    if (!isMultiChoice) {
      let option = from.find(option => option.id === id)

      // keep the filter value but set the value to be shown in capture to the selected one
      if (props.keepFilter) {
        setOptionValue(option ? option.text : id)
      } else {
        onChangeFilter(null, '')
      }
      setIsShowingOptions(false)
    }
  }

  function onClickAddFilterAsCustomOption() {
    let item = { id: valueFilter, text: valueFilter }
    setFromCustom(list => [...list, item])
    onClick(item.id)
  }

  function isSelected(item) {
    return isMultiChoice
      ? props.value.includes(item.id)
      : props.value === item.id
  }

  function onKeyUp(event) {
    if (props.onKeyUp) {
      props.onKeyUp(event)
    } else if (event.key === 'Enter' && typeof props.onSubmit === 'function') {
      props.onSubmit(event)

      // clear the filter so that the currently selected value is displayed
      setValueFilter('')
    } else if (event.key === 'Escape') {
      setIsShowingOptions(false)
    }
  }

  function onFocus(event) {
    if (props.disabled) return

    setIsShowingOptions(true)
    inputRef.current?.select()
    if (typeof onFocusBind === 'function') {
      onFocusBind(event)
    }
  }

  function onBlur(event) {
    if (
      !(
        event.relatedTarget === optionsRef.current ||
        optionsRef.current?.contains(event.relatedTarget)
      )
    ) {
      setIsShowingOptions(false)
    }
    if (typeof onBlurBind === 'function') {
      onBlurBind(event)
    }

    if (props.clearFilterOnBlur) {
      // clear the filter so that the currently selected value is displayed
      setValueFilter('')
    }
  }

  function getFromCustom() {
    if (!props.value || !customOptionsAvailable) return []

    let value = isMultiChoice ? props.value : [props.value]
    // all values that are not part of the list of available options will be treated as custom values
    return value
      .filter(id => !from.some(item => item.id === id))
      .map(id => ({ id, text: id }))
  }

  function getPlaceholder() {
    let selected = [...fromCustom, ...from]
      .filter(isSelected)
      .map(item => item.text)
    if (!selected.length) return props.disabled ? '' : props.placeholder

    return selected.length === 1 || !isMultiChoice
      ? selected[0]
      : `${selected.length} selected`
  }

  function onClickToggleAll() {
    if (!Array.isArray(props.value)) return
    if (typeof props.onChange !== 'function') return

    if (isSelectedAll) {
      if (isEmpty) return

      props.onChange([])
    } else {
      props.onChange(from.map(item => item.id))
    }
  }
}

function useFilteredValues(list, filter) {
  return useMemo(() => {
    if (filter === '') return list

    let filterRegex = new RegExp(escapeRegex(filter), 'i')
    return list.filter(item => filterRegex.test(item.text))
  }, [list, filter])
}
