import React from 'react'
import { hasHTML, replaceUrlsWithLinks } from 'Data/format.js'
import RawHtml from '../RawHtml/react.js'
import './style.css'

export default function NoteHtml(props) {
  return (
    <div className="note-html">
      <RawHtml
        html={
          hasHTML(props.html) ? props.html : replaceUrlsWithLinks(props.html)
        }
      ></RawHtml>
    </div>
  )
}
